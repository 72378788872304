import { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';
import {
  Drawer,
  IconButton,
  InputAdornment,
  Grid,
  Paper,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Tooltip,
  TooltipProps,
  TablePagination,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { decode } from 'html-entities';
import { debounce } from 'lodash';

import {
  IGarmScoreDto,
  ShowEpisodesType,
  GarmScoresPerShowBody,
} from 'src/types';
import useDialog from 'src/hooks/useDialog';
import useDashboardCategories from 'src/hooks/useDashboardCategories';
import {
  METRICS_TYPE,
  submitMetrics,
  formatFilename,
  formatHostSentiment,
} from 'src/utils';
import Spinner from 'src/components/Spinner';
import Button from 'src/components/NewButton';
import { parseEpisodesOfShow } from 'src/apis/episode';
import useNotification from 'src/hooks/useNotification';
import { scoringEpisode } from 'src/apis/selfScoring';

import EpisodeRow from './EpisodeRow';
import UnscoredEpisodeRow from './UnscoredEpisodeRow';
import Footer from './Footer';
import HeaderCell from './HeaderCell';
import TableWrapper from './TableWrapper';

interface GarmDashboardShowDetailsProps {
  columns: string[];
  garmScoreColumns: string[];
  genres: string[];
  hostList?: string[];
  showEpisodes: Nullable<ShowEpisodesType>;
  selectedShow: string;
  logoUrl: Nullable<string>;
  loading?: boolean;
  onChangeShow: (
    show: Nullable<string>,
    body: GarmScoresPerShowBody,
    flag?: boolean
  ) => void;
  onSelectEpisode: (episode: Nullable<IGarmScoreDto>) => void;
  dashboardId: Nullable<string>;
  currentRssFeedUrl: string;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#fcfcfe',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 12,
    border: '1px solid #dadde9',
    maxWidth: 560,
    padding: 0,
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 100px)',
  },
}));

const GarmDashboardShowDetails: FC<GarmDashboardShowDetailsProps> = ({
  columns,
  garmScoreColumns,
  genres,
  hostList = [],
  showEpisodes,
  selectedShow,
  logoUrl,
  loading = false,
  onChangeShow,
  onSelectEpisode,
  dashboardId,
  currentRssFeedUrl,
}) => {
  const classes = useStyles();
  const [enableUnscored, setEnableUnscored] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState('Episode Date');
  const [order, setOrder] = useState<'desc' | 'asc' | undefined>('desc');
  const [searchText, setSearchText] = useState('');
  const [showArticles, setShowArticles] = useState<any>(null);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [scoringList, setScoringList] = useState<string[]>([]);
  const { riskComponents } = useDashboardCategories();

  const imagePath = formatFilename(selectedShow);
  const { handleSuccess, handleError } = useNotification();

  const { onClose } = useDialog();
  const [hostDataJson, setHostDataJson] = useState<any>({});

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event: any) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const debounceFetchShowEpisodes = useRef(
    debounce(
      async (
        show: Nullable<string>,
        body: GarmScoresPerShowBody,
        flag: boolean
      ) => {
        onChangeShow(show, body, flag);
      },
      900
    )
  ).current;

  useEffect(() => {
    let sortBy = orderBy;
    switch (orderBy) {
      case 'Name':
        sortBy = 'name';
        break;
      case 'Episode Date':
        sortBy = 'publishedAt';
        break;
      default:
        sortBy = riskComponents[sortBy];
        break;
    }
    const body: GarmScoresPerShowBody = {
      page,
      pageSize,
      search: searchText,
      sortOrder: order === 'asc' ? 'ASC' : 'DESC',
      sortBy,
    };
    debounceFetchShowEpisodes(selectedShow, body, enableUnscored);
  }, [
    debounceFetchShowEpisodes,
    selectedShow,
    page,
    pageSize,
    searchText,
    order,
    orderBy,
    enableUnscored,
  ]);

  useEffect(() => {
    import('src/assets/hostdata.json').then((data) => {
      setHostDataJson(data);
    });
  }, []);

  const handleClose = () => {
    onClose();
    onChangeShow(null, {});
  };

  const handleChangeSearchText: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSearchText(event.target.value);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const addDefaultSrc = (ev: any) => {
    ev.target.src = '/images/placeholder.png';
  };

  const refreshEpisodes = async () => {
    try {
      setRefreshing(true);
      const data = await parseEpisodesOfShow(selectedShow);
      if (data.status === 'success') {
        handleSuccess(data.message);
        let sortBy = orderBy;
        switch (orderBy) {
          case 'Name':
            sortBy = 'name';
            break;
          case 'Episode Date':
            sortBy = 'publishedAt';
            break;
          default:
            sortBy = riskComponents[sortBy];
            break;
        }
        const body: GarmScoresPerShowBody = {
          page,
          pageSize,
          search: searchText,
          sortOrder: order === 'asc' ? 'ASC' : 'DESC',
          sortBy,
        };
        debounceFetchShowEpisodes(selectedShow, body, enableUnscored);
      } else {
        handleError({}, data.message || 'Sorry, please try again later');
      }
    } catch {
      handleError({}, 'Sorry, please try again later');
    } finally {
      setRefreshing(false);
    }
  };

  const handleScore = async (episodeId: string) => {
    if (dashboardId) {
      setScoringList([...scoringList, episodeId]);
      await scoringEpisode(episodeId, dashboardId, currentRssFeedUrl);
    }
  };

  useEffect(() => {
    submitMetrics(METRICS_TYPE.SHOW_EPISODES);
  }, []);

  return (
    <Drawer
      anchor='right'
      open
      onClose={handleClose}
      className={classes.drawer}
    >
      <div
        style={{
          padding: '12px',
          paddingTop: '6px',
          position: 'relative',
          display: 'flex',
          flex: 1,
          flexGrow: 1,
          height: '100%',
          flexWrap: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <div className={classes.header}>
          <IconButton onClick={handleClose} size='small'>
            <CloseIcon color='primary' />
          </IconButton>
          <img
            src={logoUrl || imagePath}
            alt='episode'
            onError={addDefaultSrc}
          />
          <div className={classes.title}>
            <Typography variant='subtitle2'>{decode(selectedShow)}</Typography>
            <Typography variant='body1' className={classes.genresTitle}>
              Genres:
            </Typography>
            <Typography variant='body2' className={classes.genre}>
              {genres.map((genre, index) =>
                index === genres.length - 1 ? genre : genre + ', '
              )}
            </Typography>
          </div>
          {/* <div className={classes.unscoredToggle}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableUnscored}
                  onChange={() => {
                    setEnableUnscored(!enableUnscored);
                  }}
                  name='viewUnscoredEpisodes'
                  color='primary'
                />
              }
              label={
                enableUnscored
                  ? `No. unscored episodes: ${showEpisodes?.unscoredCount}`
                  : 'Display all episodes'
              }
            />
            <Button
              onClick={() => refreshEpisodes()}
              height='24px'
              fontSize='sm'
            >
              Refresh episodes
            </Button>
          </div> */}
          <div className={classes.hostList}>
            {hostList.map((host, index) => {
              const data: any =
                hostDataJson[`agg_${host}` as keyof typeof hostDataJson];
              const sentimentX: any = {
                article: 0,
                score: 0,
                articlesDetail: [],
              };
              const sentimentY: any = {
                article: 0,
                score: 0,
                articlesDetail: [],
              };
              if (data) {
                Object.keys(data.num_of_article).forEach((key) => {
                  sentimentX.article = data.num_of_article[key];
                  sentimentX.score = data.total_score[key];
                  sentimentX.articlesDetail = data.articles[key];
                  sentimentY.article =
                    sentimentY.article + data.num_of_article[key];
                  sentimentY.score = sentimentY.score + data.total_score[key];
                  sentimentY.articlesDetail = [
                    ...sentimentY.articlesDetail,
                    ...data.articles[key],
                  ];
                });
                sentimentY.score =
                  sentimentY.score / Object.keys(data.num_of_article).length;
              }

              return (
                <div key={index} className={classes.host}>
                  <Typography variant='body1' className={classes.genresTitle}>
                    {`${host} (${index + 1})`}
                  </Typography>
                  {data ? (
                    <>
                      <CustomTooltip
                        interactive
                        title={
                          <table className={classes.hostArticles}>
                            <tr>
                              <th>Article Title</th>
                              <th>Date</th>
                            </tr>
                            {sentimentX.articlesDetail &&
                              sentimentX.articlesDetail.map(
                                (article: any, index: number) => (
                                  <tr key={index}>
                                    <td>
                                      <a
                                        href={article.url}
                                        rel='noreferrer'
                                        target='_blank'
                                      >
                                        {article.title}
                                      </a>
                                    </td>
                                    <td>
                                      {article.time &&
                                        format(
                                          new Date(article.time),
                                          'MM/dd/yy'
                                        )}
                                    </td>
                                  </tr>
                                )
                              )}
                          </table>
                        }
                        placement='bottom-start'
                      >
                        <Typography
                          variant='body2'
                          className={classes.hostcontent}
                          onClick={() =>
                            setShowArticles(sentimentX.articlesDetail)
                          }
                        >
                          Host sentiment is{' '}
                          {formatHostSentiment(sentimentX.score)} across{' '}
                          {sentimentX.article} articles in the past week.
                        </Typography>
                      </CustomTooltip>
                      <CustomTooltip
                        interactive
                        title={
                          <table className={classes.hostArticles}>
                            <tr>
                              <th>Article Title</th>
                              <th>Date</th>
                            </tr>
                            {sentimentY.articlesDetail &&
                              sentimentY.articlesDetail.map(
                                (article: any, index: number) => (
                                  <tr key={index}>
                                    <td>
                                      <a
                                        href={article.url}
                                        rel='noreferrer'
                                        target='_blank'
                                      >
                                        {article.title}
                                      </a>
                                    </td>
                                    <td>
                                      {article.time &&
                                        format(
                                          new Date(article.time),
                                          'MM/dd/yy'
                                        )}
                                    </td>
                                  </tr>
                                )
                              )}
                          </table>
                        }
                        placement='bottom-start'
                      >
                        <Typography
                          variant='body2'
                          className={classes.hostcontent}
                          onClick={() =>
                            setShowArticles(sentimentY.articlesDetail)
                          }
                        >
                          Host sentiment is{' '}
                          {formatHostSentiment(sentimentY.score)} across{' '}
                          {sentimentY.article} articles in the last 4 weeks.
                        </Typography>
                      </CustomTooltip>
                    </>
                  ) : (
                    <Typography variant='body2' className={classes.genre}>
                      This host did not appear in any news stories this week.
                    </Typography>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <TableContainer
          component={Paper}
          style={{
            overflowX: 'scroll',
            boxShadow: '0px 0px 0px 1px #E0E0E0',
            borderRadius: 4,
            flex: 1,
            flexGrow: 1,
          }}
        >
          <TableWrapper
            stickyHeader
            style={{ borderRadius: 4, height: '100%' }}
          >
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 2,
              }}
            >
              <TableRow>
                <HeaderCell
                  sortDirection={orderBy === columns[0] ? order : false}
                  style={{ boxShadow: 'inset 0 -7px 2px -7px rgba(0,0,0,0.4)' }}
                >
                  <TableSortLabel
                    active={orderBy === columns[0]}
                    direction={orderBy === columns[0] ? order : 'asc'}
                    onClick={() => handleRequestSort(columns[0])}
                  >
                    {columns[0]}
                  </TableSortLabel>
                </HeaderCell>
                <HeaderCell
                  sortDirection={orderBy === 'Episode Date' ? order : false}
                  style={{ boxShadow: 'inset 0 -7px 2px -7px rgba(0,0,0,0.4)' }}
                >
                  <TableSortLabel
                    active={orderBy === 'Episode Date'}
                    direction={orderBy === 'Episode Date' ? order : 'asc'}
                    onClick={() => handleRequestSort('Episode Date')}
                  >
                    Air Date
                  </TableSortLabel>
                </HeaderCell>
                {columns.slice(1).map((column: string, index: number) => (
                  <HeaderCell
                    key={index}
                    sortDirection={orderBy === column ? order : false}
                    style={
                      index === columns?.length - 1
                        ? {
                            minWidth: 220,
                            boxShadow: 'inset 0 -7px 2px -7px rgba(0,0,0,0.4)',
                          }
                        : {
                            minWidth: 220,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            boxShadow: 'inset 0 -7px 2px -7px rgba(0,0,0,0.4)',
                          }
                    }
                  >
                    <TableSortLabel
                      active={orderBy === column}
                      direction={orderBy === column ? order : 'asc'}
                      onClick={() => handleRequestSort(column)}
                    >
                      {column}
                    </TableSortLabel>
                  </HeaderCell>
                ))}
              </TableRow>
            </TableHead>
            {loading || refreshing ? (
              <TableRow style={{ height: '100%', border: 'none' }}>
                <TableCell colSpan={columns.length + 1}>
                  <div
                    style={{
                      width: '100vw',
                      height: 'calc(100vh - 300px)',
                      position: 'fixed',
                      bottom: '10%',
                    }}
                  >
                    <Spinner />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {showEpisodes?.data.map((episode, index) =>
                  episode.isUnscored ? (
                    <UnscoredEpisodeRow
                      key={index}
                      data={{
                        id: episode.id,
                        'Episode Title': episode.episodeTitle,
                        'Episode Date': episode.pubDate,
                        status: episode.status,
                      }}
                      buttonSpan={columns.length}
                      handleScore={handleScore}
                      scoringList={scoringList}
                    />
                  ) : (
                    <EpisodeRow
                      key={`${episode.Show}-${index}`}
                      name={episode.Show}
                      columns={columns}
                      garmScoreColumns={garmScoreColumns}
                      data={episode}
                      onClick={() => onSelectEpisode(episode)}
                    />
                  )
                )}
                {/* This final row exists so that the other table rows don't expand in height (and look distorted) if there aren't enough rows to fill the full height of the table */}
                <TableRow style={{ height: '100%', border: 'none' }}>
                  <TableCell />
                </TableRow>
              </TableBody>
            )}
            <Footer
              style={{
                position: 'sticky',
                bottom: 0,
                height: 65,
              }}
            >
              <TableRow>
                <TableCell
                  colSpan={1000}
                  style={{
                    padding: '6px 12px',
                    boxShadow: 'inset 0 7px 2px -7px rgba(0,0,0,0.4)',
                  }}
                >
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    style={{
                      position: 'sticky',
                      top: '50%',
                      left: 20,
                      width: 'calc(100vw - 60px)',
                    }}
                  >
                    <Grid item>
                      <TextField
                        label='Search Records'
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            fontWeight: 'normal',
                            flex: 1,
                            width: '100%',
                          },
                        }}
                        style={{ width: '100%' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <SearchIcon
                                htmlColor='#757575'
                                style={{ fontSize: 24 }}
                              />
                            </InputAdornment>
                          ),
                          style: { width: '100%' },
                        }}
                        value={searchText}
                        onChange={handleChangeSearchText}
                      />
                    </Grid>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangePageSize}
                      count={showEpisodes?.totalCount || 0}
                      page={page}
                      rowsPerPage={pageSize}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        fontSize: 12,
                      }}
                      nextIconButtonProps={{
                        size: 'small',
                      }}
                      backIconButtonProps={{
                        size: 'small',
                      }}
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            </Footer>
          </TableWrapper>
        </TableContainer>
        {showArticles && showArticles.length && (
          <div className={classes.articlesModal}>
            <div className={classes.circleClose}>
              <CloseIcon
                color='primary'
                fontSize='small'
                onClick={() => setShowArticles(null)}
              />
            </div>
            <table className={classes.hostArticles}>
              <tr>
                <th>Article Title</th>
                <th>Date</th>
              </tr>
              {showArticles &&
                showArticles.map((article: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <a href={article.url} rel='noreferrer' target='_blank'>
                        {article.title}
                      </a>
                    </td>
                    <td>
                      {article.time &&
                        format(new Date(article.time), 'MM/dd/yy')}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        )}
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12,
      '& > img': {
        width: 42,
        height: 42,
        borderRadius: 4,
        marginLeft: 10,
      },
      marginBottom: 6,
    },
    unscoredToggle: {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      marginLeft: 60,
      marginRight: 80,
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    genresTitle: {
      fontSize: 12,
      lineHeight: '100%',
      opacity: 0.7,
      marginBottom: 1,
    },
    genre: {
      fontSize: 10,
      lineHeight: '100%',
      opacity: 0.7,
    },
    drawer: {
      '& > .MuiDrawer-paper': {
        maxWidth: '100%',
        overflow: 'auto',
      },
    },
    hostList: {
      overflowX: 'auto',
      overflowY: 'hidden',
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      gap: 30,
      marginLeft: 50,
      paddingTop: 2,
      height: 'calc(100% - 2px)',
      '&::-webkit-scrollbar': {
        height: 6,
      },
      '&::-webkit-scrollbar-track ': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    host: {
      minWidth: 350,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 2,
      position: 'relative',
    },
    hostcontent: {
      fontSize: 10,
      lineHeight: '100%',
      opacity: 0.7,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    hostArticles: {
      borderCollapse: 'collapse',
      '& th, & td': {
        border: '1px solid #dddddd',
        padding: 8,
        textAlign: 'center',
        fontWeight: 'bold',
      },
      '& td': {
        fontWeight: 'normal',
      },
      '& td a': {
        color: 'black',
        cursor: 'pointer',
      },
    },
    articlesModal: {
      backgroundColor: '#f1f1f5',
      position: 'absolute',
      bottom: 12,
      right: 12,
      maxWidth: 560,
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 100px)',
    },
    circleClose: {
      backgroundColor: '#ffffff',
      borderRadius: '100%',
      position: 'absolute',
      top: 8,
      left: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 24,
      height: 24,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f9f9fe',
      },
    },
  })
);

export default GarmDashboardShowDetails;
