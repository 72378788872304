import React from 'react';
import { Box, styled, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CampaignItemProps } from 'src/types/campaign';
import { normalizeRiskValue } from 'src/hooks/useDashboardFilters';
import { RISK_COLOR_ASSOCIATION } from '../dashboard/garm/common';
import { RISK_TEXT_ASSOCIATION } from '../dashboard/garm/common';
import { ReactComponent as AlignedBadgeIcon } from 'src/assets/icons/alignedBadge.svg';
import { ReactComponent as UnAlignedBadgeIcon } from 'src/assets/icons/unAlignedBadge.svg';

const useStyles = makeStyles(() =>
  createStyles({
    nodssi: {
      backgroundColor: RISK_COLOR_ASSOCIATION['nodssi'],
      color: '#A4A4AC',
    },
    noRisk: {
      backgroundColor: RISK_COLOR_ASSOCIATION['norisk'],
      color: '#4A4A56',
    },
    lowRisk: {
      backgroundColor: RISK_COLOR_ASSOCIATION['low'],
      color: '#9333EA',
    },
    medRisk: {
      backgroundColor: RISK_COLOR_ASSOCIATION['medium'],
      color: '#FFFFFF',
    },
    highRisk: {
      backgroundColor: RISK_COLOR_ASSOCIATION['high'],
      color: '#FFFFFF',
    },
    scoreCell: {
      minHeight: 48,
      width: 108,
      minWidth: 108,
      padding: 4,
      border: '1px solid #F0F0F1',

      '& > div': {
        width: '100%',
        height: '100%',
        borderRadius: 2,
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',
        fontWeight: 600,
        fontSize: 10,
        lineHeight: '12px',
        position: 'relative',
        textTransform: 'uppercase',
      },
    },
    tableFont: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '0.15px',
    },
    tableCell: {
      minHeight: 48,
      padding: '4px 10px',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      color: '#2D2D38',
      border: '1px solid #F0F0F1',
    },
  })
);

export const RowContainer = styled(TableRow)(() => ({
  '&:hover': {
    opacity: 0.8,
  },
}));
export const CellContent = styled(Box)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
}));

interface CampaignItemRowProps {
  columns: string[];
  garmScoreColumns: string[];
  data: CampaignItemProps;
  onClick: (showName: string, episodeName: string) => void;
}

const dataMap = {
  Episode: 'Episode Title',
  'Podcast Title': 'Show',
  'Adult & Explicit Sexual Content': 'GARM 1 Risk',
  'Obscenity & Profanity': 'GARM 7 Risk',
  'Debated Sensitive Social Issues (Political issues)': 'GARM 11 Risk',
  'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcohol': 'GARM 8 Risk',
  'Arms & Ammunition': 'GARM 2 Risk',
  'Online Piracy or Spam': 'GARM 5&9 Risk',
  'Crime & Violation of Human Rights': 'GARM 3 Risk',
  'Death, Injury or Military Conflict': 'GARM 4 Risk',
  'Hate Speech & Acts of Aggression': 'GARM 6 Risk',
  Terrorism: 'GARM 10 Risk',
  Gambling: 'GAMBLING Risk',
  Occult: 'OCCULT Risk',
  'Natural Disasters': 'NATURAL DISASTER Risk',
  Genres: 'Genres',
  'No. of stars': 'No. of stars',
  'Top IAB Categories': 'Top IAB Categories',
  'General Election': 'GENERAL ELECTIONS Risk',
};

const CampaignItemEpisodeRow = ({
  data,
  columns,
  garmScoreColumns,
  onClick,
}: CampaignItemRowProps) => {
  const classes = useStyles();
  const riskClasses: { [x: string]: string } = {
    nodssi: classes.nodssi,
    norisk: classes.noRisk,
    low: classes.lowRisk,
    medium: classes.medRisk,
    high: classes.highRisk,
  };

  const renderGarmScoreCell = (col: string, index: number) => {
    if (col === 'Name') return null;
    let riskVal = data.episode[dataMap[col as keyof typeof dataMap]];

    riskVal = normalizeRiskValue(riskVal);

    return (
      <TableCell
        key={index}
        style={{
          minWidth: '180px',
          color: '#000',
          textAlign: 'center',
          fontSize: '13px',
        }}
        className={classes.scoreCell}
      >
        <div className={riskClasses[riskVal]}>
          {RISK_TEXT_ASSOCIATION[riskVal]}
        </div>
      </TableCell>
    );
  };

  return (
    <RowContainer
      style={{
        borderColor: '#E1E1E1',
        cursor: 'pointer',
      }}
      onClick={() => {
        onClick(data.episode['Show'], data.episode['Episode Title']);
      }}
    >
      {columns.map((col: string, index: number) => {
        if (garmScoreColumns.includes(col)) {
          return renderGarmScoreCell(col, index);
        } else if (col === 'Impressions') {
          return (
            <TableCell
              key={index}
              style={{
                width: '120px',
                minWidth: '120px',
                color: '#000',
                padding: '10px',
                fontSize: '16px',
              }}
              align='center'
              className={classes.tableFont}
            >
              <CellContent>{data['impressionCount']}</CellContent>
            </TableCell>
          );
        } else if (col === 'Aligned') {
          return (
            <TableCell
              key={index}
              style={{
                width: '120px',
                minWidth: '120px',
                color: '#000',
                padding: '10px',
                fontSize: '16px',
              }}
              align='center'
              className={classes.tableFont}
            >
              <CellContent>
                {data['isAligned'] ? (
                  <AlignedBadgeIcon />
                ) : (
                  <UnAlignedBadgeIcon />
                )}
              </CellContent>
            </TableCell>
          );
        } else {
          return (
            <TableCell
              key={index}
              style={{
                minWidth: '120px',
                color: '#000',
                padding: '10px',
                fontSize: '16px',
              }}
              align='left'
              className={classes.tableFont}
            >
              <CellContent>
                {data['episode'][dataMap[col as keyof typeof dataMap]]}
              </CellContent>
            </TableCell>
          );
        }
      })}
    </RowContainer>
  );
};

export default CampaignItemEpisodeRow;
