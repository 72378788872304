import { useContext, useState, createContext } from 'react';

import { CampaignProps } from 'src/types/campaign';

type CampaignContextType = {
  campaigns: CampaignProps[];
  setCampaigns: (items: CampaignProps[]) => void;
  selectedCampaignId: string | null;
  setSelectedCampaignId: (id: string | null) => void;
  openCreateModal: boolean;
  setOpenCreateModal: (id: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const CampaignContext = createContext<CampaignContextType>(undefined!);

export function CampaignProvider(props: any) {
  const [campaigns, setCampaigns] = useState<CampaignProps[]>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(
    null
  );
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  const values = {
    campaigns,
    setCampaigns,
    selectedCampaignId,
    setSelectedCampaignId,
    openCreateModal,
    setOpenCreateModal,
  };

  return <CampaignContext.Provider value={values} {...props} />;
}

export default function useCampaign() {
  return useContext(CampaignContext);
}
