import { FC, useEffect, useState, useCallback } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  styled,
  Typography,
  createStyles,
  makeStyles,
  TextField,
  InputAdornment,
  Box,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import Button from 'src/components/Button';
import { Theme } from 'src/theme/types/createPalette';
import { getCampaignList } from 'src/apis/campaign';
import useCampaign from 'src/hooks/useCampaign';

import { ReactComponent as CampaignsIcon } from 'src/assets/icons/campaigns.svg';

export const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      height: 'calc(100vh - 50px)',
    },
    mainContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      maxHeight: '100%',
      overflow: 'auto',
    },
    addBtn: {
      background: '#ffffff',
      color: '#000000',
      border: '1px solid #000000',
      fontWeight: 'normal',
      fontSize: 16,
      '&.MuiButton-containedPrimary:hover': {
        backgroundColor: '#5EA1F2',
        color: '#fff',
      },
    },
    searchBar: {
      width: '100%',
      border: '1px solid #C3C3C7',
      padding: '2px 8px',
      borderRadius: 36,

      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        border: 'none !important',
      },
    },
    campaignItem: {
      padding: 0,
      alignItems: 'flex-start',
    },
  })
);

export const DashboardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 20,
  lineHeight: '24px',
  fontWeight: 500,
}));

export const ListContainer = styled(List)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

export const ListItemContainer = styled(ListItem)(() => ({
  display: 'flex',
  flex: 1,
  gap: 20,

  '& .MuiListItemText-primary': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
  },
  '& .MuiListItemText-secondary': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
  },
}));

interface CampaignListSidebarProps {}

const CampaignListSidebar: FC<CampaignListSidebarProps> = () => {
  const classes = useStyles();
  const { campaigns, setCampaigns, setSelectedCampaignId, setOpenCreateModal } =
    useCampaign();
  const [searchText, setSearchText] = useState<string>('');

  const fetchCampaigns = useCallback(async () => {
    const response = await getCampaignList();
    setCampaigns(response.campaigns);
  }, []);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const handleChangeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
  };

  return (
    <div className={classes.container}>
      <DashboardTitle variant='h5'>All Campaigns</DashboardTitle>
      <TextField
        InputLabelProps={{
          shrink: true,
          style: { fontWeight: 'normal' },
        }}
        InputProps={{
          placeholder: 'Search campaigns...',
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon htmlColor='#858591' style={{ fontSize: 20 }} />
            </InputAdornment>
          ),
        }}
        value={searchText}
        onChange={handleChangeSearchText}
        className={classes.searchBar}
      />
      <Box
        flex={1}
        display='flex'
        flexDirection='column'
        gridRowGap='8px'
        style={{ overflow: 'auto' }}
      >
        {campaigns
          .filter((campaign) =>
            campaign.name.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((campaign) => (
            <ListItem
              key={campaign.id}
              button
              className={classes.campaignItem}
              onClick={() => {
                setSelectedCampaignId(campaign.id);
              }}
            >
              <ListItemIcon style={{ minWidth: 30 }}>
                <CampaignsIcon
                  stroke='#757575'
                  fill='none'
                  strokeWidth={1}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  style={{ width: 16, height: 16 }}
                />
              </ListItemIcon>
              <Typography
                style={{ fontSize: 14, fontWeight: 400, lineHeight: '18px' }}
              >
                {campaign.name}
              </Typography>
            </ListItem>
          ))}
      </Box>
      <Button
        title='+ New Campaign'
        color='primary'
        fontSize='sm'
        height={36}
        width='100%'
        className={classes.addBtn}
        onClick={() => setOpenCreateModal(true)}
      />
    </div>
  );
};

export default CampaignListSidebar;
