import { useState, MouseEventHandler } from 'react';
import { Typography, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useTextStyles } from 'src/hooks/useTextStyles';

interface SelectTableHeaderProps {
  options: string[];
  header: string;
  setHeader: (header: string) => void;
}

export const SelectTableHeader = ({
  options,
  header,
  setHeader,
}: SelectTableHeaderProps) => {
  const textClasses = useTextStyles();
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const open = Boolean(anchorEl);

  const handleOpenExpert: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExport = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          cursor: 'pointer',
        }}
        onClick={handleOpenExpert}
      >
        <Typography
          className={textClasses.smBold}
          style={{
            color: '#475467',
          }}
        >
          {header}
        </Typography>
        <ArrowDropDownIcon fill='rgba(0, 0, 0, 0.54)' />
      </div>
      <Menu
        id='dashboard-menu'
        aria-labelledby='dashboard-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseExport}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {options.map((option) => (
          <MenuItem
            style={
              option === 'Ignore Column'
                ? {
                    color: '#B42318',
                  }
                : {}
            }
            key={option}
            onClick={() => {
              handleCloseExport();
              setHeader(option);
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
