import { FC, useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import _ from 'lodash';

import Spinner from 'src/components/Spinner';
import GarmArticleContent from 'src/containers/content-screen/GARMArticleContent';
import {
  IGarmScoreDto,
  IGarmScoreShowDetails,
  ShowEpisodesType,
} from 'src/types';
import useEnvironment from 'src/hooks/useEnvironment';
import {
  Footer,
  GarmDashboardShowDetails,
  HeaderCell,
  Row,
  TableWrapper,
  ShowHostDataDetails,
} from './components';
import { getHostsData } from 'src/apis';
import useDashboardFilters from 'src/hooks/useDashboardFilters';

interface GarmDashboardProps {
  currentShow: Nullable<string>;
  totalShowsCount: number;
  showEpisodes: Nullable<ShowEpisodesType>;
  loading: boolean;
  order: 'desc' | 'asc' | undefined;
  orderBy: string;
  page: number;
  pageGarmShows: IGarmScoreShowDetails[];
  rowsPerPage: number;
  onChangeShow: any;
  onChangePage: (_event: any, newPage: number) => void;
  onChangeRowsPerPage: (event: any) => void;
  onRequestSort: (property: string, index: number) => void;
  columns?: string[];
}

const GarmDashboard: FC<GarmDashboardProps> = ({
  columns = [],
  currentShow,
  totalShowsCount,
  showEpisodes,
  loading,
  order,
  orderBy,
  page,
  pageGarmShows,
  rowsPerPage,
  onChangeShow,
  onChangePage,
  onChangeRowsPerPage,
  onRequestSort,
}) => {
  const [currentEpisode, setCurrentEpisode] =
    useState<Nullable<IGarmScoreDto>>(null);
  const [currentGenres, setCurrentGenres] = useState<string[]>([]);
  const [currentHostList, setCurrentHostList] = useState<string[]>([]);
  const [currentShowLogo, setCurrentShowLogo] = useState<string>('');
  const [currentRssFeedUrl, setCurrentRssFeedUrl] = useState<string>('');
  const [hostColumns, setHostColumns] = useState<string[]>([]);
  const [showshostData, setShowshostData] = useState<{
    [x: string]: { [x: string]: any };
  }>({});
  const [hostsData, setHostsData] = useState<any>(null);
  const { dashboardEnv, timeRange } = useEnvironment();

  const { garmScoreColumns, dashboardId } = useDashboardFilters();

  const selectShow = (show: IGarmScoreShowDetails) => {
    setCurrentGenres(
      show.genreList
        ? show.genreList.split(',').map((genre) => genre.trim())
        : []
    );
    const showHostlist = show.hostList
      ? show.hostList.split(',').map((host) => host.trim())
      : [];
    const filteredHostlist = _.uniq(showHostlist);
    setCurrentHostList(filteredHostlist);
    onChangeShow(show.showOnDB, {});
    setCurrentShowLogo(show.logoUrl);
    setCurrentRssFeedUrl(show.rssFeedUrl);
  };

  const fetchHostData = useCallback(
    async (hosts: string[], shows: IGarmScoreShowDetails[]) => {
      const data = await getHostsData({
        from: timeRange.from.toISOString(),
        to: timeRange.to.toISOString(),
        hosts,
      });
      setHostsData(data);
      const newHostColumns: string[] = ['Name'];
      const hostData: { [x: string]: any } = {};
      let hostMaxCount = 0;
      shows.forEach((show) => {
        const hostList = show.hostList
          ? show.hostList.split(',').map((host) => host.trim())
          : [];
        if (hostList && hostList.length > hostMaxCount) {
          hostMaxCount = hostList.length;
        }
        hostList.forEach((host, index) => {
          const hostHistory = data[host as keyof typeof data]?.aggregations.map(
            (item: any) => ({
              index: item.id,
              publishedAt: new Date(item.publishedAt).getTime(),
              count: item.count,
              score: item.totalScore,
            })
          ) as {
            index: string;
            publishedAt: number;
            count: number;
            score: number;
          }[];
          if (!hostData[show.show]) {
            hostData[show.show] = {};
          }

          hostData[show.show][`Host ${index + 1}`] = {
            label: host,
            score:
              hostHistory && hostHistory.length
                ? hostHistory.reduce(
                    (
                      acc: number,
                      item: {
                        index: string;
                        publishedAt: number;
                        count: number;
                        score: number;
                      }
                    ) => acc + item.score,
                    0
                  ) / hostHistory.length
                : null,
          };
        });
      });
      new Array(Math.max(hostMaxCount, 1)).fill(0).forEach((_, index) => {
        newHostColumns.push('Host ' + (index + 1));
      });
      setHostColumns(newHostColumns);
      setShowshostData(hostData);
    },
    [timeRange]
  );

  useEffect(() => {
    let hosts: string[] = [];
    pageGarmShows.forEach((show) => {
      const hostList = show.hostList
        ? show.hostList.split(',').map((host) => host.trim())
        : [];
      if (hostList.length) {
        hosts = [...hosts, ...hostList];
      }
    });
    fetchHostData(hosts, pageGarmShows);
  }, [pageGarmShows, fetchHostData]);

  return (
    <TableContainer
      component={Paper}
      style={{
        overflowX: 'scroll',
        boxShadow: '0px 0px 0px 1px #E0E0E0',
        borderRadius: 4,
        flex: 1,
        flexGrow: 1,
      }}
    >
      <TableWrapper
        stickyHeader
        style={{ borderRadius: 4, height: '100%', borderCollapse: 'collapse' }}
      >
        <TableHead
          style={{
            position: 'sticky',
            top: -1,
            zIndex: 2,
          }}
        >
          <TableRow>
            {(dashboardEnv === 'Host' ? hostColumns : columns).map(
              (column: string, index: number) => (
                <HeaderCell
                  key={column}
                  sortDirection={orderBy === column ? order : false}
                  style={
                    index === 0
                      ? {
                          width: '20%',
                          minWidth: 300,
                          border: '1px solid #F0F0F1',
                          borderRightWidth: 4,
                        }
                      : {
                          padding: '4px 10px',
                          borderRadius: 0,
                          minWidth: 220,
                          border: '1px solid #F0F0F1',
                          borderBottom: 'none',
                          background: '#F8F8F8',
                        }
                  }
                >
                  {dashboardEnv === 'Host' ? (
                    <span>{column}</span>
                  ) : (
                    <TableSortLabel
                      disabled={['Top IAB Categories', 'Genres'].includes(
                        column
                      )}
                      active={orderBy === column}
                      direction={orderBy === column ? order : 'asc'}
                      onClick={() => {
                        if (column === 'No. of stars') {
                          onRequestSort('rating', index - 1);
                        } else {
                          onRequestSort(column, index - 1);
                        }
                      }}
                    >
                      {column}
                    </TableSortLabel>
                  )}
                </HeaderCell>
              )
            )}
          </TableRow>
        </TableHead>
        {loading ? (
          <TableRow style={{ height: '100%', border: 'none' }}>
            <TableCell colSpan={columns.length}>
              <Spinner />
            </TableCell>
          </TableRow>
        ) : (
          <TableBody>
            {pageGarmShows.map((show, index) => (
              <Row
                key={`${show.show}-${index}`}
                name={show.show}
                columns={dashboardEnv === 'Host' ? hostColumns : columns}
                garmScoreColumns={garmScoreColumns}
                data={show}
                hostData={showshostData[show.show]}
                isHost={dashboardEnv === 'Host'}
                onClick={() => selectShow(show)}
              />
            ))}
            {/* This final row exists so that the other table rows don't expand in height (and look distorted) if there aren't enough rows to fill the full height of the table */}
            <TableRow style={{ height: '100%', border: 'none' }}>
              <TableCell />
            </TableRow>
          </TableBody>
        )}
        <Footer
          style={{
            position: 'sticky',
            bottom: -1,
            zIndex: 1,
          }}
        >
          <TableRow>
            <TableCell
              colSpan={1000}
              style={{
                padding: '6px 12px',
                boxShadow: 'inset 0 7px 2px -7px rgba(0,0,0,0.4)',
              }}
            >
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                style={{
                  position: 'sticky',
                  top: '50%',
                  left: 20,
                  maxWidth: 500,
                }}
              >
                {/* @ts-ignore */}
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  count={totalShowsCount}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    fontSize: 12,
                  }}
                  nextIconButtonProps={{
                    size: 'small',
                  }}
                  backIconButtonProps={{
                    size: 'small',
                  }}
                />
              </Grid>
            </TableCell>
          </TableRow>
        </Footer>
      </TableWrapper>

      {currentShow &&
        (dashboardEnv === 'Host' ? (
          <ShowHostDataDetails
            selectedShow={currentShow}
            hostList={currentHostList}
            genres={currentGenres}
            logoUrl={currentShowLogo}
            onChangeShow={onChangeShow}
            hostsData={hostsData}
          />
        ) : (
          <GarmDashboardShowDetails
            columns={columns.filter((item) => item !== 'Genres')}
            garmScoreColumns={garmScoreColumns}
            showEpisodes={showEpisodes}
            selectedShow={currentShow}
            genres={currentGenres}
            hostList={currentHostList}
            logoUrl={currentShowLogo}
            loading={loading}
            onChangeShow={onChangeShow}
            onSelectEpisode={setCurrentEpisode}
            dashboardId={dashboardId}
            currentRssFeedUrl={currentRssFeedUrl}
          />
        ))}

      <Dialog open={!!currentEpisode} fullScreen>
        <GarmArticleContent
          episode={currentEpisode}
          show={currentShow || ''}
          showGenres={currentGenres}
          onClose={() => {
            setCurrentEpisode(null);
          }}
        />
      </Dialog>
    </TableContainer>
  );
};

export default GarmDashboard;
