import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { useTextStyles } from 'src/hooks/useTextStyles';
import InfoTooltip from 'src/components/InfoTooltip';
import { ReactComponent as HelpIcon } from 'src/assets/icons/helpCircle.svg';

const useStyles = makeStyles(() =>
  createStyles({
    layout: {
      minWidth: 320,
      border: '1px solid #EAECF0',
      borderRadius: 8,
      padding: 8,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    info: {
      position: 'absolute',
      top: 12,
      right: 12,
    },
  })
);

interface StatProps {
  title: string;
  value: string;
  description: string;
  info?: string;
}

export const Stat = ({ title, value, description, info }: StatProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();

  return (
    <Box className={classes.layout}>
      <Typography className={textClasses.smBold} style={{ color: '#344054' }}>
        {title}
      </Typography>
      <Box display='flex' alignItems='center' gridGap={12}>
        <Typography
          className={textClasses.xxlBold}
          style={{ color: '#101828' }}
        >
          {value}
        </Typography>
        <Typography
          className={textClasses.smRegular}
          style={{ color: '#101828' }}
        >
          {description}
        </Typography>
      </Box>
      {!!info && (
        <InfoTooltip
          className={classes.info}
          content={<Typography>{info}</Typography>}
        >
          <HelpIcon width={16} height={16} />
        </InfoTooltip>
      )}
    </Box>
  );
};
