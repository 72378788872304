import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useTextStyles = makeStyles(() =>
  createStyles({
    xxlBold: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '32px',
      color: '#2D2D38',
    },
    xlBold: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '30px',
      color: '#2D2D38',
    },
    lgBold: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '28px',
      color: '#2D2D38',
    },
    baseBold: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      color: '#2D2D38',
    },
    baseRegular: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      color: '#2D2D38',
    },
    smBold: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
      color: '#2D2D38',
    },
    smMedium: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      color: '#2D2D38',
    },
    smRegular: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      color: '#2D2D38',
    },
    xsBold: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
      color: '#2D2D38',
    },
    xsMedium: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '18px',
      color: '#2D2D38',
    },
    xsRegular: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      color: '#2D2D38',
    },
    xxsRegular: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: '12px',
      color: '#2D2D38',
    },
  })
);
