import React from 'react';
import { Box, styled, TableCell, TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import { RISK_TEXT_ASSOCIATION, useStyles } from '../common';
import { normalizeRiskValue } from 'src/hooks/useDashboardFilters';
import useDashboardCategories from 'src/hooks/useDashboardCategories';
import { FiveStarsRating } from 'src/components/FiveStarsRating';

import { USElectionTableCell } from './USElectionTableCell';

export const EpisodeRowContainer = styled(TableRow)(() => ({
  '&:hover': {
    opacity: 0.8,
  },
}));
export const CellContent = styled(Box)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
}));

interface EpisodeRowProps {
  name: string;
  columns: string[];
  garmScoreColumns: string[];
  data: { [x: string]: any };
  onClick: () => void;
}

const EpisodeRow = ({
  data,
  columns,
  garmScoreColumns,
  onClick,
}: EpisodeRowProps) => {
  const classes = useStyles();
  const { riskKeyByTitleMap } = useDashboardCategories();
  const riskClasses: { [x: string]: string } = {
    nodssi: classes.nodssi,
    norisk: classes.noRisk,
    low: classes.lowRisk,
    medium: classes.medRisk,
    high: classes.highRisk,
  };

  let publishedAt = null;

  if (data['Episode Date']) {
    try {
      publishedAt = format(new Date(data['Episode Date']), 'MM/dd/yyyy');
    } catch {
      publishedAt = '-';
    }
  } else {
    publishedAt = '-';
  }

  const renderGarmScoreCell = (col: string, index: number) => {
    if (col === 'Name') return null;
    let riskVal = data[riskKeyByTitleMap[col]];

    riskVal = normalizeRiskValue(riskVal);

    return (
      <TableCell
        key={index}
        style={{
          minWidth: '180px',
          color: '#000',
          textAlign: 'center',
          fontSize: '13px',
        }}
        className={classes.scoreCell}
      >
        <div className={riskClasses[riskVal]}>
          {RISK_TEXT_ASSOCIATION[riskVal]}
        </div>
      </TableCell>
    );
  };

  const renderCell = (content: string[], width: number) => (
    <TableCell
      style={{
        width: width || 165,
        minWidth: width || 165,
      }}
      align='left'
      className={classes.tableCell}
    >
      {content}
    </TableCell>
  );

  return (
    <EpisodeRowContainer
      style={{
        borderColor: '#E1E1E1',
        cursor: 'pointer',
      }}
      onClick={() => {
        onClick();
      }}
    >
      <TableCell
        style={{
          width: '350px',
          minWidth: '320px',
          color: '#000',
          padding: '10px',
          fontSize: '16px',
          borderRight: '1px solid #E1E1E1',
        }}
        className={classes.tableFont}
      >
        <CellContent>{data['Episode Title']}</CellContent>
      </TableCell>
      <TableCell
        style={{
          width: '120px',
          minWidth: '120px',
          color: '#000',
          padding: '10px',
          fontSize: '16px',
        }}
        align='center'
        className={classes.tableFont}
      >
        <CellContent>{publishedAt}</CellContent>
      </TableCell>
      {columns.map((col: string, index: number) => {
        if (garmScoreColumns.includes(col)) {
          return renderGarmScoreCell(col, index);
        } else if (col === 'US Elections') {
          return (
            <USElectionTableCell
              key={index}
              content={data['ELECTIONS PERCENTAGE'] || 0}
              up={42}
              down={0}
              isSecondary
            />
          );
        } else if (col === 'Top IAB Categories') {
          return (
            <TableCell
              key={index}
              style={{
                width: 230,
                minWidth: 230,
              }}
              align='left'
              className={classes.tableCell}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: 10,
                  rowGap: 6,
                  flexWrap: 'wrap',
                  overflowY: 'auto',
                  maxHeight: 48,
                }}
              >
                {(data['IAB New Data'] || data['IAB Data']) &&
                  Object.keys(
                    data['IAB New Data'] || data['IAB Data'][0][0]
                  ).map((category: any) => (
                    <CellContent key={category} className={classes.cellChip}>
                      {category}
                    </CellContent>
                  ))}
              </div>
            </TableCell>
          );
        } else if (col === 'Genres') {
          return renderCell(data['Genres'], 165);
        } else if (col === 'No. of stars') {
          return (
            <TableCell
              key={index}
              style={{
                width: 230,
                minWidth: 230,
              }}
              align='left'
              className={classes.tableCell}
            >
              <FiveStarsRating value={data['Listeners'] || 0} />{' '}
              {data['Listeners'] && data['Listeners'] !== '0'
                ? data['Listeners']
                : ''}
            </TableCell>
          );
        }
        return null;
      })}
    </EpisodeRowContainer>
  );
};

export default EpisodeRow;
