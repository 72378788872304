import { createContext, useContext, useState } from 'react';

type SidebarContextType = {
  filterSidebarOpen?: boolean;
  isSmallSidebar?: boolean;
  dashboardSidebarOpen?: boolean;
  campaignsSideBar?: boolean;
  secondSidebarOpen: boolean;
  secSidebarWidth: number;
  width: number;
  setSecSidebarWidth: (width: number) => void;
  setWidth: (width: number) => any;
  setSecondSidebarOpen: (value: boolean) => void;
  setDashboardSidebar: (value: boolean) => void;
  setCampaignsSideBar: (value: boolean) => void;
  toggleDashboardSidebar: () => void;
  toggleFilterSidebar: () => void;
  openFilterSidebar: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SidebarContext = createContext<SidebarContextType>(undefined!);

export const DRAWER_WIDTH = 246;
export const DRAWER_WIDTH_SMALL = 67;
export const SECONDARY_DRAWER_WIDTH = 306;

export function SidebarProvider(props: any) {
  const [width, setWidth] = useState<number>(DRAWER_WIDTH);
  const [secondSidebarOpen, setSecondSidebarOpen] = useState<boolean>(false);
  const [filterSidebarOpen, setFilterSidebarOpen] = useState<boolean>(true);
  const [dashboardSidebarOpen, setDashboardSidebar] = useState<boolean>(false);
  const [campaignsSideBar, setCampaignsSideBar] = useState<boolean>(false);
  const [secSidebarWidth, setSecSidebarWidth] = useState<number>(
    SECONDARY_DRAWER_WIDTH
  );

  const toggleDashboardSidebar = () => {
    setFilterSidebarOpen(false);
    setDashboardSidebar((prev) => !prev);
  };

  const toggleFilterSidebar = () => {
    setDashboardSidebar(false);
    setFilterSidebarOpen((prev) => !prev);
  };

  const openFilterSidebar = () => {
    setDashboardSidebar(false);
    setFilterSidebarOpen(true);
  };

  const isSmallSidebar = width === DRAWER_WIDTH_SMALL;

  const values: SidebarContextType = {
    width,
    secondSidebarOpen,
    secSidebarWidth,
    setSecSidebarWidth,
    setWidth,
    setSecondSidebarOpen,
    setDashboardSidebar,
    toggleDashboardSidebar,
    dashboardSidebarOpen,
    toggleFilterSidebar,
    filterSidebarOpen,
    isSmallSidebar,
    openFilterSidebar,
    campaignsSideBar,
    setCampaignsSideBar,
  };

  return <SidebarContext.Provider value={values} {...props} />;
}

export default function useSidebar() {
  return useContext(SidebarContext);
}
