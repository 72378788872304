import { useRef, useState, DragEvent, ChangeEvent } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { read, utils } from 'xlsx';

import { useTextStyles } from 'src/hooks/useTextStyles';
import Button from 'src/components/NewButton';
import { HeaderCell } from 'src/containers/dashboard/garm/components';
import { SelectTableHeader } from './SelectTableHeader';
import Checkbox from 'src/components/Checkbox';
import useNotification from 'src/hooks/useNotification';

import { ReactComponent as DownloadIcon } from 'src/assets/icons/download.svg';
import { ReactComponent as UploadIcon } from 'src/assets/icons/uploadCloud.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      height: '100%',
    },
    importContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    dragDropImport: {
      flex: 1,
      position: 'relative',

      '& > input': {
        display: 'none',
      },

      '& > label': {
        display: 'flex',
        border: '1px dashed var(--gray-200, #EAECF0)',
        background: '#FFF',
        padding: '16px 24px',
        height: '100%',
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',

        '&.drag-active': {
          background: '#F7F7F7',
        },

        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
        },
      },

      '& #drag-file-element': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: 8,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    downloadBtn: {
      '& > span': {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
      },
    },
    uploadIcon: {
      marginBottom: 8,
      width: 42,
      height: 42,
      borderRadius: 8,
      border: '1px solid var(--gray-200, #EAECF0)',
      padding: 10,
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
    importedData: {
      flex: 1,
      position: 'relative',
      border: '1px solid var(--gray-200, #EAECF0)',
      background: '#FFF',
      padding: '16px 24px',
      height: '100%',
      borderRadius: 8,
    },
    table: {
      flex: 1,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      border: '1px solid #EAECF0',
      borderRadius: 8,
      background: '#fff',
      height: '100%',
      borderCollapse: 'collapse',
      overflow: 'auto',
      maxHeight: 308,

      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          borderBottom: '1px solid #EAECF0',

          '&:last-child': {
            borderBottom: 'none',
          },

          '& .MuiTableCell-root': {
            borderBottom: 'none',
            borderRight: '1px solid #EAECF0',

            '&:last-child': {
              borderRight: 'none',
            },
          },
        },
      },
    },
    noRSSFeed: {
      marginLeft: 4,
      '& span': {
        fontSize: 12,
        fontWeight: 'normal',
      },
      '& .MuiIconButton-label': {
        transform: 'scale(0.7)',
      },
    },
  })
);

const FileTypes = ['csv', 'sheet', 'xls', 'xlsx'];

interface ImportStepProps {
  data: Record<string, string>[];
  setData: (data: Record<string, string>[]) => void;
  isNoRSSFeed: boolean;
  setIsNoRSSFeed: (isNoRSSFeed: boolean) => void;
}

export const ImportStep = ({
  data,
  setData,
  isNoRSSFeed,
  setIsNoRSSFeed,
}: ImportStepProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleError } = useNotification();

  const [dragActive, setDragActive] = useState<boolean>(false);

  const handleFile = (file: File) => {
    if (
      file.size > 1048576 ||
      FileTypes.every((type) => !file.type.includes(type))
    ) {
      handleError({}, 'Please upload CSV or XLS file less than 1 MB.');
    } else {
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event?.target?.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            raw: false,
            defval: '',
          });
          if (rows.length) {
            setData(rows);
          } else {
            handleError({}, 'Please add data to your file.');
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleDrag = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const downloadTemplate = () => {
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + '/TemplateImport.xlsx';
    link.download = 'TemplateImport.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.importContent}>
        <Typography className={textClasses.smBold} style={{ color: '#344054' }}>
          Import Show List and RSS Feeds
        </Typography>
        <Typography
          className={textClasses.xsRegular}
          style={{ color: '#667085' }}
        >
          Choose or drop an Excel or CSV file of all shows (maximum 100) you
          would like to import into your dashboard to be scored. Include the
          show&apos;s name as well as its RSS feed.
        </Typography>
        <Box className={classes.dragDropImport} onDragEnter={handleDrag}>
          <input
            ref={inputRef}
            type='file'
            id='input-file-upload'
            onChange={handleChange}
          />
          {data && data.length ? (
            <TableContainer component={Paper} className={classes.table}>
              <Table>
                <TableHead
                  style={{
                    height: 48,
                  }}
                >
                  <TableRow>
                    {Object.keys(data[0]).map((column: string) => (
                      <HeaderCell
                        key={column}
                        style={{
                          padding: '4px 10px',
                          borderRadius: 0,
                          borderBottom: 'none',
                          background: '#F8F8F8',
                        }}
                      >
                        <SelectTableHeader
                          options={['Show Title', 'RSS Feed']}
                          header={column}
                          setHeader={(newHeader) => {
                            if (newHeader !== column) {
                              const temp = [...data].map((item) => {
                                const newItem: Record<string, string> = {};
                                Object.keys(item).forEach((key) => {
                                  if (key === column) {
                                    newItem[newHeader] = item[column];
                                  } else {
                                    newItem[key] = item[key];
                                  }
                                });
                                return newItem;
                              });
                              setData(temp);
                            }
                          }}
                        />
                      </HeaderCell>
                    ))}
                    <HeaderCell
                      align='center'
                      style={{
                        padding: '4px 10px',
                        borderRadius: 0,
                        borderBottom: 'none',
                        background: '#F8F8F8',
                      }}
                    ></HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) => (
                    <TableRow
                      key={index}
                      style={{
                        height: '48px',
                      }}
                    >
                      {Object.keys(item).map((key) => (
                        <TableCell key={key}>
                          <Typography
                            className={textClasses.xsRegular}
                            style={{
                              color: '#475467',
                            }}
                          >
                            {item[key]}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <label
              id='label-file-upload'
              htmlFor='input-file-upload'
              className={dragActive ? 'drag-active' : ''}
            >
              <div>
                <div className={classes.uploadIcon}>
                  <UploadIcon width={20} height={20} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <Button
                    variant='text'
                    fontSize='sm'
                    height={20}
                    onClick={handleUploadClick}
                  >
                    <Typography
                      className={textClasses.smBold}
                      style={{ color: '#5EA1F2' }}
                    >
                      Click to upload
                    </Typography>
                  </Button>
                  <Typography
                    className={textClasses.smRegular}
                    style={{ color: '#475467' }}
                  >
                    or drag and drop
                  </Typography>
                </div>
                <Typography
                  className={textClasses.xsRegular}
                  style={{ color: '#475467' }}
                >
                  CSV or XLS
                </Typography>
              </div>
            </label>
          )}
          {dragActive && (
            <div
              id='drag-file-element'
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
        </Box>
        <Typography
          className={textClasses.xsRegular}
          style={{ color: '#667085' }}
        >
          <span style={{ fontWeight: 500 }}>Required fields:</span> Show Title
          {isNoRSSFeed ? '' : ', RSS Feed'}
        </Typography>
        <Box className={classes.noRSSFeed}>
          <Checkbox
            name='No RSS Feed'
            label='No RSS Feed'
            checked={isNoRSSFeed}
            noPadding={true}
            onChange={setIsNoRSSFeed}
          />
        </Box>
      </Box>
      <Box>
        <Button
          className={classes.downloadBtn}
          variant='outlined'
          fontSize='sm'
          height={38}
          onClick={downloadTemplate}
        >
          <DownloadIcon />
          <Typography
            className={textClasses.smBold}
            style={{ color: '#344054' }}
          >
            Download Template
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
