export const ROUTES = {
  DASHBOARD: '/dashboard',
  AUDIT: '/audit',
  LANDING: '/landing',
  LOGIN: '/login',
  // disable register now
  // REGISTER: '/register',
  TEAM: '/team',
  SUBSCRIPTION: '/subscription',
  FORGOT_PASSWORD: '/forgot-password',
  ACCEPT_INVITATION: '/accept-invitation',
  CONFIRM_RESET_CODE: '/confirm-reset-code',
  TOC: '/toc',
  SES_WHITE_PAPER: '/SESwhitepaper',
  RESET_PASSWORD: '/reset-password',
  SCORE_CONTENT: '/score-content',
  SETTINGS: '/settings',
  DOCUMENTATION: '/documentation',
  COOKIE_POLICY: '/cookie-policy',
  CONTENT_VIEW: '/content-view',
  REPORTS: '/reports',
  SPOTLIGHT: '/spotlight',
  STANDARD_FILTERS: '/settings/standard-filters',
  API_KEY: '/settings/api-key',
  SELF_SCORING: '/settings/self-scoring',
  SEARCH: '/search',
  CAMPAIGNS: '/campaigns',
  BLOG: '/blog',
};
