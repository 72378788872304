import { useState } from 'react';
import {
  Grid,
  Typography,
  Collapse,
  makeStyles,
  createStyles,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SmileIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import { Droppable } from 'react-beautiful-dnd';

import TextInput from 'src/components/TextInput';
import { Dashboard } from 'src/types';
import { DashboardItem } from './DashboardItem';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 0,
      alignItems: 'flex-start',
    },
    collapseTitle: {
      fontSize: 14,
      fontWeight: 400,
    },
    collapseCounter: {
      border: '1px solid #000000',
      borderRadius: 2,
      fontSize: 12,
      lineHeight: '12px',
      padding: 4,
      marginRight: 8,
    },
    collapseContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      margin: '8px 0',
      padding: 8,
      paddingLeft: 20,
      paddingRight: 8,
      position: 'relative',

      '&::before': {
        content: '""',
        position: 'absolute',
        left: 8,
        width: 1,
        top: 4,
        bottom: 4,
        borderLeft: '1px solid gray',
      },
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      flex: 1,
    },
    titleInput: {
      '& .MuiInputBase-input': {
        fontSize: 14,
        fontWeight: 400,
        paddingTop: 0,
      },
    },
  })
);

interface DashboardFolderProps {
  name: string;
  dashboards: Dashboard[];
  onSelectDashboard: (dashboard: Dashboard) => void;
  onDeleteFolder: (name: string) => void;
  onRenameFolder: (name: string, newName: string) => void;
  isDashboardSelection?: boolean;
}

export const DashboardFolder = ({
  name,
  dashboards,
  isDashboardSelection = false,
  onDeleteFolder,
  onRenameFolder,
  onSelectDashboard,
}: DashboardFolderProps) => {
  const classes = useStyles();
  const [folderName, setFolderName] = useState<string>(name);
  const [isRenaming, setIsRenaming] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openFolders, setOpenFolders] = useState<boolean>(false);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRename = () => {
    setIsRenaming(true);
    handleCloseMenu();
  };

  const handleFinishEditing = () => {
    setIsRenaming(false);
    if (name !== folderName) {
      onRenameFolder(name, folderName);
    }
  };

  const handleOpenFolders = (flag: boolean) => {
    if (dashboards && dashboards.length) {
      setOpenFolders(flag);
    }
  };

  return (
    <Droppable droppableId={name}>
      {(provided) => (
        <Grid container ref={provided.innerRef} {...provided.droppableProps}>
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{ cursor: 'pointer' }}
          >
            <div
              className={classes.nameContainer}
              onClick={() => {
                !isRenaming && handleOpenFolders(!openFolders);
              }}
            >
              <SmileIcon htmlColor='#757575' style={{ fontSize: 18 }} />
              {isRenaming ? (
                <TextInput
                  className={classes.titleInput}
                  value={folderName}
                  // autoFocus
                  onChange={setFolderName}
                  onEnter={handleFinishEditing}
                  onBlur={handleFinishEditing}
                />
              ) : (
                <Typography className={classes.collapseTitle}>
                  {name} ({dashboards.length || 0})
                </Typography>
              )}
            </div>
            {openFolders ? (
              <KeyboardArrowUpIcon
                onClick={() => {
                  handleOpenFolders(!openFolders);
                }}
                style={{ fontSize: 18 }}
              />
            ) : (
              <KeyboardArrowDownIcon
                onClick={() => {
                  handleOpenFolders(!openFolders);
                }}
                style={{ fontSize: 18 }}
              />
            )}
            {!isDashboardSelection && (
              <div>
                <IconButton
                  onClick={handleOpenMenu}
                  style={{ padding: 2, marginLeft: 10 }}
                >
                  <MoreVertIcon htmlColor='#757575' style={{ fontSize: 16 }} />
                </IconButton>

                <Menu
                  id='folder-menu'
                  aria-labelledby='folder-button'
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  onClick={handleCloseMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem onClick={handleRename}>Rename</MenuItem>
                  <MenuItem
                    onClick={() => {
                      onDeleteFolder(name);
                      handleCloseMenu();
                    }}
                    style={{ color: '#ff0000' }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Grid>
          <Collapse
            in={openFolders}
            timeout='auto'
            unmountOnExit
            style={{ width: '100%' }}
          >
            <Grid container className={classes.collapseContainer}>
              {dashboards.map((dashboard, index) => (
                <DashboardItem
                  key={dashboard.id}
                  index={index}
                  dashboard={dashboard}
                  onSelectDashboard={onSelectDashboard}
                />
              ))}
              {provided.placeholder}
            </Grid>
          </Collapse>
        </Grid>
      )}
    </Droppable>
  );
};
