import { cloneDeep } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Dashboard, DashboardView, SelfScoringProcessTypes } from 'src/types';
import { BrandCustomFilter } from 'src/types/filter';
import useAuth from './useAuth';

import useDashboardCategories from './useDashboardCategories';
import {
  getPublishers,
  getIABCategories,
  getGarmSources,
  getViewsAPI,
} from 'src/apis';
import { getAllExceptionFilters } from 'src/apis/exception';

export const normalizeRiskValue = (val: string) => {
  if (!val || typeof val !== 'string') return 'nodssi';
  const v = val.toLowerCase().replace(/\s+/g, '');

  // Normalize inconsistent backend data until API settles
  if (v === 'no') {
    return 'norisk';
  }

  return v;
};

export const RISK_VALUES: { [x: string]: number } = {
  nodssi: -1,
  norisk: 0,
  low: 1,
  medium: 2,
  high: 3,
};

export const RISK_STRINGS: { [x: number]: string } = {
  0: 'No Risk',
  1: 'Low',
  2: 'Medium',
  3: 'High',
};

export const getNumericRiskValue = (riskValue: string) => {
  return RISK_VALUES[normalizeRiskValue(riskValue)];
};

export const getRiskString = (riskValue: number | null) => {
  if (riskValue === null) {
    return '';
  }
  return RISK_STRINGS[Math.round(riskValue)];
};

export type RiskFilterType = {
  enabled: boolean;
  value: number | number[];
  title: string;
  jsonKeyRisk: string;
  filterType?: string;
  dashboardFilterKey: string;
  tooltip?: string;
  tooltipLink?: {
    label: string;
    href: string;
  };
};

type DashboardFilterContextType = {
  dashboardId: Nullable<string>;
  dashboardName: string;
  riskFilters: Record<string, RiskFilterType>;
  garmSourceFilters: string[];
  riskChangeFilter: string;
  selectedPublishers: string[];
  publishers: string[];
  selectedIABCategories: any[];
  selectedLanguages: string[];
  iabCategories: any[];
  garmSources: string[];
  genres: string[];
  customTags: string[];
  selectedGenres: string[];
  excludedGenres: string[];
  enabledFilters: RiskFilterType[];
  garmDashboardColumns: string[];
  garmScoreColumns: string[];
  selectLanguages: (selections: string[]) => void;
  selectIABCategories: (selections: any[]) => void;
  selectPuplisher: (selections: string[]) => void;
  selectGenres: (selections: string[]) => void;
  setExcludedGenres: (selections: string[]) => void;
  setRiskChangeFilter: (risk: string) => void;
  changeDashboardName: (name: string) => void;
  selectDashboard: (dashboard: Dashboard | null) => void;
  setFilterValue: (key: string, val: number | number[]) => void;
  toggleRiskFilter: (key: string) => void;
  riskFilterEnabled: (key: string) => boolean;
  getFilterValue: (key: string) => number | number[] | undefined;
  getFilterTitle: (key: string) => string;
  setGarmSourceFilters: (showNames: string[]) => void;
  initDashboard: () => void;
  selectedView: any[];
  setSelectedView: (data: string[]) => void;
  dashboardViews: DashboardView[];
  fetchOrganizationViews: () => void;
  selectedViewId: Nullable<string>;
  setSelectedViewId: (id: Nullable<string>) => void;
  customFilters: BrandCustomFilter[];
  selectedCustomFilter: BrandCustomFilter | null;
  setSelectedCustomFilter: (item: BrandCustomFilter | null) => void;
  setSelectedCustomFilterId: (item: string) => void;
  refreshCustomFilters: () => void;
  selectedSelfScoring: SelfScoringProcessTypes | undefined;
  electionRange: number[];
  setElectionRange: (ranges: number[]) => void;
  selectedCustomTags: string[];
  setSelectedCustomTags: (selections: string[]) => void;
  enabledUpdateDashboard: boolean;
  setEnabledUpdateDashboard: (flag: boolean) => void;
};

export const DashboardFilterContext = createContext<DashboardFilterContextType>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!
);

export function DashboardFilterProvider(props: any) {
  const { filterMapInit } = useDashboardCategories();
  const [dashboardId, setDashboardId] = useState<Nullable<string>>(null);

  const [dashboardName, setDashboardName] = useState('');
  const [riskChangeFilter, setRiskChangeFilter] = useState('all');

  const [riskFilters, setRiskFilters] = useState(cloneDeep(filterMapInit));
  const [publishers, setPublishers] = useState<any[]>([]);
  const [selectedPublishers, selectPuplisher] = useState<string[]>([]);
  const [iabCategories, setIabCategories] = useState<any[]>([]);
  const [selectedLanguages, selectLanguages] = useState<string[]>([]);
  const [selectedIABCategories, selectIABCategories] = useState<any[]>([]);
  const [garmSources, setGramSources] = useState<string[]>([]);
  const [garmSourceFilters, setGarmSourceFilters] = useState<string[]>([]);
  const [genres, setGenres] = useState<string[]>([]);
  const [customTags, setCustomTags] = useState<string[]>([]);
  const [selectedGenres, selectGenres] = useState<string[]>([]);
  const [excludedGenres, setExcludedGenres] = useState<string[]>([]);
  const [selectedCustomTags, setSelectedCustomTags] = useState<string[]>([]);
  const [customFilters, setCustomFilters] = useState<BrandCustomFilter[]>([]);
  const [selectedCustomFilter, setSelectedCustomFilter] =
    useState<BrandCustomFilter | null>(null);
  const [selectedCustomFilterId, setSelectedCustomFilterId] = useState<
    string | null
  >(null);
  const [selectedSelfScoring, setSelectedSelfScoring] = useState<
    SelfScoringProcessTypes | undefined
  >(undefined);
  const [electionRange, setElectionRange] = useState<number[]>([0]);
  const [enabledUpdateDashboard, setEnabledUpdateDashboard] =
    useState<boolean>(false);

  const [selectedView, setSelectedView] = useState<any[]>([]);
  const [selectedViewId, setSelectedViewId] = useState<Nullable<string>>(null);
  const [dashboardViews, setDashboardViews] = useState<DashboardView[]>([]);
  const { user } = useAuth();
  const enableFetchDataRef = useRef<boolean>(true);

  const fetchOrganizationViews = useCallback(async () => {
    try {
      const data = await getViewsAPI();
      setDashboardViews(data);
    } catch {
      setDashboardViews([]);
    }
  }, []);

  const refreshCustomFilters = async () => {
    try {
      const data = await getAllExceptionFilters();
      setCustomFilters(data);
    } catch {
      setCustomFilters([]);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await getPublishers();
      setPublishers(data.filter((item: any) => !!item && !!item.publisher));
    } catch {
      setPublishers([]);
    }
    try {
      const data = await getIABCategories();
      setIabCategories(data);
    } catch {
      setIabCategories([]);
    }
    try {
      const data: any = await getGarmSources();
      setGenres(data.genres.filter((item: string) => !!item).sort());
      setCustomTags(data.customTags.filter((item: string) => !!item).sort());
      setGramSources([...data.names]);
    } catch {
      setGenres([]);
      setCustomTags([]);
      setGramSources([]);
    }
    try {
      const data = await getAllExceptionFilters();
      setCustomFilters(data);
    } catch {
      setCustomFilters([]);
    }
  }, []);

  useEffect(() => {
    setRiskFilters(cloneDeep(filterMapInit));
    if (enableFetchDataRef.current) {
      fetchData();
      enableFetchDataRef.current = false;
    }
  }, [filterMapInit, fetchData]);

  useEffect(() => {
    if (user?.organizationId) {
      fetchOrganizationViews();
      setSelectedView([]);
    }
  }, [user, fetchOrganizationViews]);

  useEffect(() => {}, [dashboardId]);

  useEffect(() => {
    if (selectedCustomFilterId) {
      setSelectedCustomFilter(
        customFilters.filter((item) => item.id === selectedCustomFilterId)[0]
      );
    } else {
      setSelectedCustomFilter(null);
    }
  }, [customFilters, selectedCustomFilterId]);

  const setFilterValue = (key: string, value: number | number[]) => {
    const f = { ...riskFilters };
    if (f[key]) {
      f[key].value = value;
      setRiskFilters(f);
    }
  };

  const getFilterValue = (key: string) => riskFilters[key]?.value;

  const getFilterTitle = (key: string) => riskFilters[key]?.title;

  const toggleRiskFilter = (key: string) => {
    const f = { ...riskFilters };
    if (f[key]) {
      f[key].enabled = !f[key].enabled;
      setRiskFilters(f);
    }
  };

  const riskFilterEnabled = (key: string) => {
    return riskFilters[key]?.enabled || false;
  };

  const garmScoreColumns = useMemo(() => {
    const enabledTitles: string[] = [];

    Object.keys(riskFilters).forEach((key) => {
      if (riskFilters[key].enabled && riskFilters[key].title) {
        enabledTitles.push(riskFilters[key].title);
      }
    });

    return enabledTitles;
  }, [riskFilters]);

  const garmDashboardColumns = useMemo(() => {
    if (selectedView.length) {
      let tempColumns: string[] = [];
      selectedView.forEach((item) => {
        if (item.type === 'score') {
          tempColumns = [
            ...tempColumns,
            ...item.data
              .filter((score: any) => !score.hide)
              .map((showScore: any) => showScore.title),
          ];
        } else {
          if (!item.hide) {
            tempColumns.push(item.title);
          }
        }
      });

      return tempColumns;
    } else {
      const externalColumns = [
        'US Elections',
        'Top IAB Categories',
        'Genres',
        'No. of stars',
      ];

      return [...garmScoreColumns, ...externalColumns];
    }
  }, [garmScoreColumns, selectedView]);

  const enabledFilters = useMemo(() => {
    const temp: RiskFilterType[] = [];

    Object.keys(riskFilters).forEach((key) => {
      if (
        riskFilters[key].enabled ||
        riskFilters[key].filterType === 'audience'
      ) {
        temp.push(riskFilters[key]);
      }
    });

    return temp;
  }, [riskFilters]);

  const changeDashboardName = useCallback((name: string) => {
    setDashboardName(name);
  }, []);

  const initDashboard = () => {
    setDashboardId(null);
    setDashboardName('');
    setRiskChangeFilter('all');
    setRiskFilters(cloneDeep(filterMapInit));
    selectPuplisher([]);
    selectGenres([]);
    setExcludedGenres([]);
    selectIABCategories([]);
    selectLanguages([]);
    setGarmSourceFilters([]);
    setSelectedSelfScoring(undefined);
    setElectionRange([0, 100]);
  };

  const selectDashboard = useCallback(
    (dashboard: Dashboard | null) => {
      if (dashboard) {
        setDashboardId(dashboard.id);
        setEnabledUpdateDashboard(true);
        setDashboardName(dashboard.name);
        setGarmSourceFilters(dashboard.keywordsList || []);

        const filters = cloneDeep(filterMapInit);

        Object.values(dashboard.garmFilters || {}).forEach((filter: any) => {
          const found = Object.entries(filterMapInit).find(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([_, each]) =>
              each.dashboardFilterKey === filter?.dashboardFilterKey
          );
          if (found) {
            filters[found[0]] = {
              ...filters[found[0]],
              ...filter,
              enabled: true,
            };
          }
        });

        setRiskFilters(filters);

        selectPuplisher(
          (dashboard.garmFilters?.publishersList as string[]) || []
        );
        selectIABCategories(
          (dashboard.garmFilters?.iabCategories as any[]) || []
        );
        selectLanguages((dashboard.garmFilters?.languages as string[]) || []);
        selectGenres((dashboard.garmFilters?.genresList as string[]) || []);
        setExcludedGenres(
          (dashboard.garmFilters?.excludedGenres as string[]) || []
        );
        setSelectedViewId(
          (dashboard.garmFilters?.selectedViewId as Nullable<string>) || null
        );
        setSelectedView([]);
        setSelectedCustomFilterId(dashboard.exceptionFilterId);
        setSelectedSelfScoring(dashboard.selfScoring);
        setElectionRange(
          (dashboard.garmFilters?.electionRange as number[]) || [0, 100]
        );
      } else {
        initDashboard();
      }
    },
    [filterMapInit, selectedView]
  );

  const values: DashboardFilterContextType = {
    riskFilters,
    dashboardId,
    dashboardName,
    garmSourceFilters,
    riskChangeFilter,
    selectedPublishers,
    publishers,
    iabCategories,
    selectedIABCategories,
    selectedLanguages,
    garmSources,
    genres,
    selectedGenres,
    excludedGenres,
    enabledFilters,
    garmDashboardColumns,
    garmScoreColumns,
    selectLanguages,
    selectIABCategories,
    selectPuplisher,
    selectGenres,
    setExcludedGenres,
    setRiskChangeFilter,
    selectDashboard,
    initDashboard,
    changeDashboardName,
    setFilterValue,
    toggleRiskFilter,
    riskFilterEnabled,
    getFilterValue,
    getFilterTitle,
    setGarmSourceFilters,
    selectedView,
    setSelectedView,
    dashboardViews,
    fetchOrganizationViews,
    selectedViewId,
    setSelectedViewId,
    customFilters,
    selectedCustomFilter,
    setSelectedCustomFilter,
    setSelectedCustomFilterId,
    refreshCustomFilters,
    selectedSelfScoring,
    electionRange,
    setElectionRange,
    selectedCustomTags,
    setSelectedCustomTags,
    customTags,
    enabledUpdateDashboard,
    setEnabledUpdateDashboard,
  };

  return <DashboardFilterContext.Provider value={values} {...props} />;
}

export default function useDashboardFilters() {
  return useContext(DashboardFilterContext);
}
