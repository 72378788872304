import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { useTextStyles } from 'src/hooks/useTextStyles';
import Button from 'src/components/Button';

const useStyles = makeStyles(() =>
  createStyles({
    layout: {
      display: 'flex',
      height: '100%',
    },
    container: {
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      background: '#fff',
      height: '100%',
      overflow: 'auto',
      flex: 1,
    },
    noContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    addBtn: {
      background: '#ffffff',
      color: '#000000',
      border: '1px solid #000000',
      fontWeight: 'normal',
      fontSize: 16,
      marginTop: 30,
      '&.MuiButton-containedPrimary:hover': {
        backgroundColor: '#5EA1F2',
        color: '#fff',
      },
    },
  })
);

interface NoCampaignProps {
  onNewCampaign: () => void;
}

export const NoCampaign = ({ onNewCampaign }: NoCampaignProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();

  return (
    <Box className={classes.layout}>
      <Box className={classes.container}>
        <Box className={classes.noContent}>
          <img
            src={'/images/campaigns/noCampaign.png'}
            width={220}
            height={160}
            alt='no result logo'
          />
          <Typography
            className={textClasses.xlBold}
            style={{ color: '#101828', marginTop: 24 }}
          >
            No Campaign Selected
          </Typography>
          <Typography
            className={textClasses.baseRegular}
            style={{
              color: '#475467',
              marginTop: 8,
              textAlign: 'center',
              maxWidth: 352,
            }}
          >
            Select a campaign from the left or create a new one to get started
          </Typography>
          <Button
            title='Create New Campaign'
            color='primary'
            fontSize='sm'
            height={44}
            className={classes.addBtn}
            onClick={onNewCampaign}
          />
        </Box>
      </Box>
    </Box>
  );
};
