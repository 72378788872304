import { baseAPICreator } from './apiCreator';
import {
  NewCampaignRequestBody,
  CampaignProps,
  CampaignListResponse,
  CampaignDetailProps,
  CampaignItemProps,
  CampaignItemsRequestProps,
} from 'src/types/campaign';

const campaignApi = baseAPICreator('/campaign');

export const getCampaignList = async (): Promise<CampaignListResponse> => {
  const { data } = await campaignApi.get('/?page=0&pageSize=1000');
  return data;
};
export const getCampaignDetail = async (
  campaignId: string,
  garmExceptions?: any
): Promise<CampaignDetailProps> => {
  const { data } = await campaignApi.post(`/${campaignId}/details`, {
    garmExceptions,
  });
  return data;
};

export const getCampaignShowNames = async (
  campaignId: string
): Promise<string[]> => {
  const { data } = await campaignApi.get(`/${campaignId}/shownames`);
  return data;
};

export const createCampaign = async (
  body: NewCampaignRequestBody
): Promise<CampaignProps> => {
  const { data } = await campaignApi.post('/', body);
  return data;
};

export const getCampaignItems = async (
  campaignId: string,
  body: CampaignItemsRequestProps,
  garmExceptions?: any
): Promise<{ count: number; items: CampaignItemProps[] }> => {
  let params = '';
  Object.keys(body).forEach((key, index) => {
    if (body[key as keyof CampaignItemsRequestProps] !== undefined) {
      params += `${index === 0 ? '?' : '&'}${key}=${
        body[key as keyof CampaignItemsRequestProps]
      }`;
    }
  });
  const { data } = await campaignApi.post(`/${campaignId}/items${params}`, {
    garmExceptions,
  });
  return data;
};
