import { useState, useEffect } from 'react';
import { Modal, Backdrop, Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useTextStyles } from 'src/hooks/useTextStyles';
import Button from 'src/components/NewButton';
import { ReactComponent as CampaignsIcon } from 'src/assets/icons/campaigns.svg';
import useDashboardFilters from 'src/hooks/useDashboardFilters';
import TextInput from 'src/components/TextInput';
import ButtonDropdown, { OptionProps } from 'src/components/ButtonDropdown';
import { ReactComponent as AlertIcon } from 'src/assets/icons/alert.svg';
import { createCampaign } from 'src/apis/campaign';
import useNotification from 'src/hooks/useNotification';
import useCampaign from 'src/hooks/useCampaign';

import { ImportTable } from './ImportTable';

interface CampaignModalProps {
  open: boolean;
  closeModal: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    content: {
      width: '100%',
      height: 500,
      marginTop: 20,
    },
    buttons: {
      paddingTop: 16,
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      '& button': {
        flex: 1,
      },
    },
    warning: {
      borderRadius: 28,
      border: '8px solid #FFFAEB',
      background: '#FEF0C7',
    },
    confirm: {
      borderRadius: 28,
      border: '8px solid #ECFDF3',
      background: '#DCFAE6',
    },
    confirmModalContent: {
      marginTop: 16,
      marginBottom: 24,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    confirmBtn: {
      flex: 1,
    },
    logo: {
      width: 50,
      height: 50,
      padding: 12,
      borderRadius: 8,
      border: '1px solid var(--Gray-200, #EAECF0)',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      height: '100%',
    },
    inputField: {
      display: 'flex',
      flexDirection: 'column',
      columnGap: 6,
    },
    description: {
      padding: '10px 14px',
      borderRadius: 8,
      border: '1px solid #C3C3C7',
      width: '100%',

      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        border: 'none !important',
      },
    },
    dropdown: {
      padding: '10px 14px',
      borderRadius: 8,
      width: '100%',
      height: '52px',
      marginTop: 4,
      justifyContent: 'flex-start',
      '& p': {
        fontSize: 16,
        fontWeight: 400,
      },
    },
    fieldsWarning: {
      padding: '8px 12px',
      border: '1px solid #FEC84B',
      borderRadius: 8,
      boxShadow: '0px 1px 2px 0px #1018280D',
      backgroundColor: '#FEF0C7',
    },
  })
);

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  background: '#fff',
  borderRadius: 8,
  boxShadow:
    '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
  padding: 24,
};

export const CampaignModal = ({ open, closeModal }: CampaignModalProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();

  const [importedData, setImportedData] = useState<Record<string, string>[]>(
    []
  );
  const [step, setStep] = useState(0);
  const [hasImportErr, setHasImportErr] = useState(false);
  const [hasFieldsErr, setFieldsErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>('');
  const [brandStandardFilterOption, setBrandStandardFilterOption] =
    useState<OptionProps | null>(null);
  const [description, setDescription] = useState<string>('');
  const { campaigns, setCampaigns, setSelectedCampaignId } = useCampaign();

  const { customFilters } = useDashboardFilters();
  const { handleError, handleSuccess } = useNotification();

  useEffect(() => {
    if (
      importedData &&
      importedData[0] &&
      Object.keys(importedData[0]).includes('Show Title') &&
      Object.keys(importedData[0]).includes('Episode Identifier') &&
      Object.keys(importedData[0]).includes('Impression Count') &&
      Object.keys(importedData[0]).includes('View Date')
    ) {
      setFieldsErr(false);
    } else {
      setFieldsErr(true);
    }
  }, [importedData]);

  const handlePrevious = () => {
    if (step === 0) {
      initModal();
    } else {
      setImportedData([]);
      setStep(step - 1);
    }
  };

  const handleNext = async () => {
    if (loading) {
      return;
    }

    if (step === 0) {
      if (name && description && importedData && importedData.length) {
        setHasImportErr(false);
        setStep(1);
      } else {
        setHasImportErr(true);
      }
    } else {
      if (hasFieldsErr) {
        return;
      }
      setLoading(true);
      try {
        const response = await createCampaign({
          name,
          description,
          exceptionFilterId: brandStandardFilterOption?.value || '',
          items:
            importedData.map((item) => ({
              showIdentifier: item['Show Title'],
              episodeIdentifier: item['Episode Identifier'],
              viewDate: new Date(item['View Date']).toISOString(),
              impressionCount: Number(item['Impression Count']),
            })) || [],
        });
        setCampaigns([response, ...campaigns]);
        setSelectedCampaignId(response.id);
        handleSuccess(`Successfully created ${name} campaign`);
        setLoading(false);
        closeModal();
      } catch (error) {
        handleError(error, 'There was an error while creating the campaign');
        setLoading(false);
      }
    }
  };

  const initModal = () => {
    setImportedData([]);
    setStep(0);
    setName('');
    setDescription('');
    setBrandStandardFilterOption(null);
  };

  return (
    <Modal
      open={open}
      aria-labelledby='self-scoring-modal-title'
      aria-describedby='self-scoring-modal-description'
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 800,
      }}
    >
      <Box sx={{ ...modalStyle }}>
        <Box className={classes.header}>
          <Box className={classes.logo}>
            <CampaignsIcon
              fill='none'
              stroke='#344054'
              strokeWidth={1}
              strokeLinecap='round'
              strokeLinejoin='round'
              style={{ width: 24, height: 24 }}
            />
          </Box>
          <CancelIcon
            cursor='pointer'
            width={24}
            height={24}
            onClick={() => closeModal()}
          />
        </Box>
        <Box display='flex' flexDirection='column'>
          <Typography
            className={textClasses.lgBold}
            style={{ color: '#101828' }}
          >
            Create Campaign{' '}
            <span style={{ fontSize: 14, fontWeight: 400 }}>
              {step + 1} of 2
            </span>
          </Typography>
          {step === 1 && (
            <Typography className={textClasses.smRegular}>
              Confirm that the following fields are mapped to the correct column
              content, and adjust or verify data as necessary.
            </Typography>
          )}
        </Box>
        <Box className={classes.content}>
          <Box className={classes.container}>
            {step === 0 && (
              <Box display='flex' gridColumnGap='20px'>
                <Box flex='1' className={classes.inputField}>
                  <Typography variant='body1' style={{ fontSize: 14 }}>
                    Campaign Name
                  </Typography>
                  <TextInput
                    style={
                      step === 0 && hasImportErr && !name
                        ? {
                            border: '1px solid #B42318',
                          }
                        : undefined
                    }
                    default='Enter name'
                    value={name}
                    onChange={(name) => setName(name)}
                    className={classes.description}
                  />
                </Box>
                <Box flex='1' className={classes.inputField}>
                  <Typography variant='body1' style={{ fontSize: 14 }}>
                    Brand Standard Filter
                  </Typography>
                  <ButtonDropdown
                    id='campaign-brand-filter'
                    defaultLabel='Select brand standard filter'
                    selectedOption={brandStandardFilterOption}
                    options={customFilters.map((filter) => ({
                      label: filter.name,
                      value: filter.id,
                    }))}
                    handleSelect={setBrandStandardFilterOption}
                    className={classes.dropdown}
                  />
                </Box>
              </Box>
            )}
            {step === 0 && (
              <Box className={classes.inputField}>
                <Typography variant='body1' style={{ fontSize: 14 }}>
                  Description
                </Typography>
                <TextInput
                  style={
                    step === 0 && hasImportErr && !description
                      ? {
                          border: '1px solid #B42318',
                        }
                      : undefined
                  }
                  default='Enter description'
                  value={description}
                  onChange={(value) => setDescription(value)}
                  className={classes.description}
                />
              </Box>
            )}
            {step === 1 && hasFieldsErr && (
              <Box
                display='flex'
                gridColumnGap='8px'
                className={classes.fieldsWarning}
              >
                <AlertIcon stroke='#DC6803' width={20} height={20} />
                <Typography
                  className={textClasses.smBold}
                  style={{ color: '#B54708' }}
                >
                  There are some fields that might be incorrectly mapped
                </Typography>
              </Box>
            )}
            <ImportTable
              data={importedData}
              setData={setImportedData}
              step={step}
              hasImportErr={hasImportErr && !importedData?.length}
            />
          </Box>
        </Box>
        <Box className={classes.buttons}>
          <Button
            title={step === 0 ? 'Cancel' : 'Previous'}
            variant='outlined'
            height={46}
            onClick={handlePrevious}
          />
          <Button height={46} onClick={handleNext}>
            {loading ? (
              <CircularProgress style={{ color: '#fff' }} size={24} />
            ) : (
              <span>{step === 1 ? 'Create Campaign' : 'Import'}</span>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
