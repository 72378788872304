import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Grid,
  Modal,
  Backdrop,
  Dialog,
} from '@material-ui/core';
import { decode } from 'html-entities';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useState, useMemo, useCallback, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { cloneDeep, keyBy } from 'lodash';

import useAuth from 'src/hooks/useAuth';
import useCampaign from 'src/hooks/useCampaign';
import useDashboardFilters, {
  RiskFilterType,
} from 'src/hooks/useDashboardFilters';
import { useTextStyles } from 'src/hooks/useTextStyles';
import { CampaignModal } from 'src/modals/CampaignModal';
import {
  getCampaignDetail,
  getCampaignItems,
  getCampaignShowNames,
} from 'src/apis/campaign';
import { getGarmScoresPerShow } from 'src/apis/garm';
import { CampaignDetailProps, CampaignItemProps } from 'src/types/campaign';
import ButtonDropdown, { OptionProps } from 'src/components/ButtonDropdown';
import { TableWrapper, HeaderCell, Footer } from '../dashboard/garm/components';
import Spinner from 'src/components/Spinner';
import Button from 'src/components/Button';
import { useCreateDashboard, useUpdateDashboard } from '../dashboard/hooks';
import { DashboardType, Dashboard, IGarmScoreDto } from 'src/types';
import { Theme } from 'src/theme/types/createPalette';
import {
  getGarmDashboards,
  formatPodcastRatingNumber,
  getValidGarmScores,
  downloadAsCsv,
  formatPodcastRating,
} from 'src/utils';
import TextInput from 'src/components/TextInput';
import { DashboardList } from 'src/components/sidebar';
import { useDashboardData } from '../dashboard/garm/hooks';
import useDashboardCategories from 'src/hooks/useDashboardCategories';
import { ScoreFilterType, ScoreExceptionTypes } from 'src/types/filter';
import GarmArticleContent from '../content-screen/GARMArticleContent';

import { NoCampaign } from './NoCampaign';
import { Stat } from './Stat';
import CampaignItemEpisodeRow from './CampaignItemEpisodeRow';
import { Row } from '../dashboard/garm/components';

const modalStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #e0e0e0',
  borderRadius: '20px 0 0 20px',
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
};

const createNewModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 450,
  bgcolor: 'background.paper',
  background: '#fff',
  borderRadius: 8,
  boxShadow:
    '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
  padding: 24,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      background: '#fff',
      minWidth: 600,
    },
    header: {
      borderBottom: '1px solid #EAECF0',
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
    },
    statsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 16,
      padding: '24px 16px',
    },
    options: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 16px',
      marginBottom: 24,
      gap: 8,
      flexWrap: 'wrap',
    },
    searchBar: {
      width: 320,
      border: '1px solid #D0D5DD',
      padding: '0 12px',
      borderRadius: 8,

      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        border: 'none !important',
      },
    },
    items: {
      width: '100%',
      overflow: 'scroll',
    },
    addBtn: {
      background: '#ffffff',
      border: '1px solid #D0D5DD',
      borderRadius: 8,
      height: 34,
      fontSize: 12,
      fontWeight: 600,
      color: 'rgb(52, 64, 84)',
    },
    downloadBtn: {
      background: '#ffffff',
      border: '1px solid #D0D5DD',
      borderRadius: 8,
      height: 34,
      fontSize: 12,
      fontWeight: 600,
      color: 'rgb(52, 64, 84)',
      width: 90,
    },
    closeIcon: {
      cursor: 'pointer',
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(3),
    },
    modalContent: {
      height: 'calc(100vh - 105px)',
      maxHeight: 'calc(100vh - 105px)',
      overflow: 'auto',
    },
    description: {
      padding: '2px 14px',
      borderRadius: 8,
      border: '1px solid #C3C3C7',
      width: '100%',
      height: 36,

      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        border: 'none !important',
      },
    },
  })
);

const SEARCH_GROUPS = [
  { label: 'By Episode', value: 'episode' },
  { label: 'By Show', value: 'show' },
];

export default function Campaigns() {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const { organization } = useAuth();
  const { selectedCampaignId, openCreateModal, setOpenCreateModal } =
    useCampaign();
  const history = useHistory();
  const { fetchAllShowNamesDashboard } = useDashboardData();
  const [showsDownloading, setShowsDownloading] = useState(false);
  const [currentCampaign, setCurrentCampaign] =
    useState<Nullable<IGarmScoreDto>>(null);
  const [currentShow, setCurrentShow] = useState<Nullable<string>>(null);
  const [currentEpisode, setCurrentEpisode] = useState<Nullable<string>>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [itemsLoading, setItemsLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<CampaignDetailProps | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [group, setGroup] = useState<OptionProps>(SEARCH_GROUPS[0]);
  const { garmScoreColumns, selectDashboard, customFilters } =
    useDashboardFilters();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [items, setItems] = useState<CampaignItemProps[]>([]);
  const [showItems, setShowItems] = useState<CampaignItemProps[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [createNewModal, setCreateNewModal] = useState<boolean>(false);
  const [newDashboardName, setNewDashboardName] = useState<string>('');
  const [brandStandardFilterOption, setBrandStandardFilterOption] =
    useState<OptionProps | null>(null);

  const { onCreateDashboard } = useCreateDashboard();
  const { onUpdateDashboard } = useUpdateDashboard();
  const { filterMapInit } = useDashboardCategories();
  const firstLoading = details?.id !== selectedCampaignId;

  const riskFilters = cloneDeep(filterMapInit);

  const enabledFilters = useMemo(() => {
    const temp: RiskFilterType[] = [];

    Object.keys(riskFilters).forEach((key) => {
      if (
        riskFilters[key].enabled ||
        riskFilters[key].filterType === 'audience'
      ) {
        temp.push(riskFilters[key]);
      }
    });
    return temp;
  }, [riskFilters]);

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchCampaignDetails = useCallback(
    async (
      id: string,
      brandStandardFilterOption: OptionProps | null,
      enableLoading?: boolean
    ) => {
      if (enableLoading) {
        setLoading(true);
      }

      try {
        await getCampaignShowNames(id);
        const selectedCustomFilter = brandStandardFilterOption?.value
          ? customFilters.filter(
              (item) => item.id === brandStandardFilterOption?.value
            )[0]
          : null;
        let garmExceptions: any = {};
        if (selectedCustomFilter) {
          enabledFilters.forEach((filter) => {
            const customFilter =
              selectedCustomFilter.data.scoreFilters[filter.title];
            if (customFilter) {
              garmExceptions[filter.dashboardFilterKey] = {
                default: getValidGarmScores(customFilter.score),
              };
              if (customFilter.content.length) {
                const content: any = {};
                customFilter.content.forEach((item: ScoreFilterType) => {
                  if (item.exceptions?.length) {
                    content[
                      item.type === ScoreExceptionTypes.Genre
                        ? 'genre'
                        : 'garmReasoning'
                    ] = item.exceptions;
                  }
                });
                garmExceptions[filter.dashboardFilterKey] = {
                  ...garmExceptions[filter.dashboardFilterKey],
                  ...content,
                };
              }
            }
          });
        } else {
          garmExceptions = undefined;
        }
        const response = await getCampaignDetail(id, garmExceptions);
        setDetails(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (selectedCampaignId) {
      fetchCampaignDetails(
        selectedCampaignId,
        brandStandardFilterOption,
        firstLoading
      );
    }
  }, [
    selectedCampaignId,
    brandStandardFilterOption,
    firstLoading,
    fetchCampaignDetails,
  ]);

  const fetchCampaignItems = useCallback(
    async (
      id: string,
      page: number,
      pageSize: number,
      groupBy: string,
      search: string,
      brandStandardFilterOption: OptionProps | null
    ) => {
      setItemsLoading(true);
      try {
        const selectedCustomFilter = brandStandardFilterOption?.value
          ? customFilters.filter(
              (item) => item.id === brandStandardFilterOption?.value
            )[0]
          : null;
        let garmExceptions: any = {};
        if (selectedCustomFilter) {
          enabledFilters.forEach((filter) => {
            const customFilter =
              selectedCustomFilter.data.scoreFilters[filter.title];
            if (customFilter) {
              garmExceptions[filter.dashboardFilterKey] = {
                default: getValidGarmScores(customFilter.score),
              };
              if (customFilter.content.length) {
                const content: any = {};
                customFilter.content.forEach((item: ScoreFilterType) => {
                  if (item.exceptions?.length) {
                    content[
                      item.type === ScoreExceptionTypes.Genre
                        ? 'genre'
                        : 'garmReasoning'
                    ] = item.exceptions;
                  }
                });
                garmExceptions[filter.dashboardFilterKey] = {
                  ...garmExceptions[filter.dashboardFilterKey],
                  ...content,
                };
              }
            }
          });
        } else {
          garmExceptions = undefined;
        }
        const response = await getCampaignItems(
          id,
          {
            page,
            pageSize,
            groupBy,
            search,
          },
          garmExceptions
        );
        if (groupBy === 'episode') {
          setItems(response.items);
        } else {
          setShowItems(response.items);
        }
        setTotalItemsCount(response.count);
        setItemsLoading(false);
      } catch (error) {
        setItemsLoading(false);
      }
    },
    [selectedCampaignId, customFilters]
  );

  useEffect(() => {
    if (selectedCampaignId) {
      fetchCampaignItems(
        selectedCampaignId,
        page,
        pageSize,
        group.value,
        searchText,
        brandStandardFilterOption
      );
    }
  }, [
    selectedCampaignId,
    page,
    pageSize,
    group,
    searchText,
    brandStandardFilterOption,
    fetchCampaignItems,
  ]);

  const garmDashboards = useMemo(
    () => (organization ? getGarmDashboards(organization) : []),
    [organization]
  );

  const columns = useMemo(() => {
    if (group.value === 'episode') {
      return [
        'Episode',
        'Podcast Title',
        'Aligned',
        'Impressions',
        ...garmScoreColumns,
      ];
    }
    return ['Podcast Title', 'Aligned', 'Impressions', ...garmScoreColumns];
  }, [group, garmScoreColumns]);

  const handleNewDashboard = async (name: string) => {
    if (!selectedCampaignId || !name) {
      return;
    }
    const showNames = await getCampaignShowNames(selectedCampaignId);

    const garmFilters = keyBy(
      Object.values(riskFilters).map((riskFilter) => ({
        dashboardFilterKey: riskFilter.dashboardFilterKey,
        enabled: riskFilter.dashboardFilterKey === 'audience' ? false : true,
        value: riskFilter.dashboardFilterKey === 'audience' ? [0, 5] : [0, 3],
      })),
      'dashboardFilterKey'
    );

    const dashboard = await onCreateDashboard({
      type: DashboardType.GARM,
      keywordsList: showNames,
      garmFilters: {
        ...garmFilters,
        publishersList: [],
        iabCategories: [],
        genresList: [],
      },
      exceptionFilterId: null,
      name,
    });

    if (dashboard) {
      selectDashboard(dashboard);
      history.push('/dashboard');
    }
  };

  const handleSelectDashboard = async (selectedDashboard: Dashboard) => {
    if (selectedDashboard && selectedCampaignId) {
      setOpenModal(false);
      const showNames = await getCampaignShowNames(selectedCampaignId);
      const existingNames = await fetchAllShowNamesDashboard(selectedDashboard);

      const garmFiltersData = {
        name: selectedDashboard.name,
        type: DashboardType.GARM,
        keywordsList: [...existingNames, ...showNames],
        garmFilters: selectedDashboard.garmFilters,
        exceptionFilterId: selectedDashboard.exceptionFilterId || null,
      };
      await onUpdateDashboard(selectedDashboard.id, garmFiltersData);
      selectDashboard({
        ...selectedDashboard,
        keywordsList: [...existingNames, ...showNames],
      });
      history.push('/dashboard');
    }
  };

  const handleDownload = async () => {
    if (!selectedCampaignId) {
      return;
    }
    setShowsDownloading(true);

    const selectedCustomFilter = brandStandardFilterOption?.value
      ? customFilters.filter(
          (item) => item.id === brandStandardFilterOption?.value
        )[0]
      : null;
    let garmExceptions: any = {};
    if (selectedCustomFilter) {
      enabledFilters.forEach((filter) => {
        const customFilter =
          selectedCustomFilter.data.scoreFilters[filter.title];
        if (customFilter) {
          garmExceptions[filter.dashboardFilterKey] = {
            default: getValidGarmScores(customFilter.score),
          };
          if (customFilter.content.length) {
            const content: any = {};
            customFilter.content.forEach((item: ScoreFilterType) => {
              if (item.exceptions?.length) {
                content[
                  item.type === ScoreExceptionTypes.Genre
                    ? 'genre'
                    : 'garmReasoning'
                ] = item.exceptions;
              }
            });
            garmExceptions[filter.dashboardFilterKey] = {
              ...garmExceptions[filter.dashboardFilterKey],
              ...content,
            };
          }
        }
      });
    } else {
      garmExceptions = undefined;
    }
    const { items } = await getCampaignItems(
      selectedCampaignId,
      {
        page: 0,
        pageSize: 1000000,
        groupBy: group.value,
        search: searchText,
      },
      garmExceptions
    );

    await downloadAsCsv({
      data: formatDownloadData(items),
      headers: [...columns],
      fileName: `${details?.name} Campaign ${group.label} Download`,
    });

    setShowsDownloading(false);
  };

  const formatDownloadData = (data: CampaignItemProps[]) => {
    if (group.value === 'episode') {
      return data
        .map((each) =>
          [
            each.episode['Episode Title'],
            each.showIdentifier,
            each.isAligned ? 'Aligned' : '',
            each.impressionCount,
            each.episode['GARM 1 Risk'],
            each.episode['GARM 7 Risk'],
            each.episode['GARM 11 Risk'],
            each.episode['GARM 8 Risk'],
            each.episode['GARM 2 Risk'],
            each.episode['GARM 5 & 9 Risk'],
            each.episode['GARM 10 Risk'],
            each.episode['GARM 4 Risk'],
            each.episode['GARM 3 Risk'],
            each.episode['GARM 6 Risk'],
            each.episode['GAMBLING Risk'],
            each.episode['OCCULT Risk'],
            each.episode['NATURAL DISASTER Risk'],
            each.episode['GENERAL ELECTIONS Risk'],
          ]
            .map((value) => {
              if (!value) return value;
              let result = String(value).replace(/"/g, '""');
              if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
              return result;
            })
            .join(',')
        )
        .join('\n');
    }
    return data
      .map((each) =>
        [
          each.show['show'],
          each.isAligned ? 'Aligned' : '',
          each.impressionCount,
          each.show?.riskHistories?.current?.garm1Risk?.medianRisk,
          each.show?.riskHistories?.current?.garm7Risk?.medianRisk,
          each.show?.riskHistories?.current?.garm11Risk?.medianRisk,
          each.show?.riskHistories?.current?.garm8Risk?.medianRisk,
          each.show?.riskHistories?.current?.garm2Risk?.medianRisk,
          each.show?.riskHistories?.current?.garm5Risk?.medianRisk,
          each.show?.riskHistories?.current?.garm10Risk?.medianRisk,
          each.show?.riskHistories?.current?.garm4Risk?.medianRisk,
          each.show?.riskHistories?.current?.garm3Risk?.medianRisk,
          each.show?.riskHistories?.current?.garm6Risk?.medianRisk,
          each.show?.riskHistories?.current?.gamblingRisk?.medianRisk,
          each.show?.riskHistories?.current?.occultRisk?.medianRisk,
          each.show?.riskHistories?.current?.naturalDisasterRisk?.medianRisk,
          each.show?.riskHistories?.current?.generalElectionsRisk?.medianRisk,
        ]
          .map((value) => {
            if (!value) return value;
            let result = String(value).replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
            return result;
          })
          .join(',')
      )
      .join('\n');
  };

  const fetchGarmScoresPerShow = async (
    showName: string,
    episodeName: string
  ) => {
    try {
      setLoading(true);
      const data = await getGarmScoresPerShow(showName, {});
      const episodes = data.data.map((item: any) => {
        const newItem = {
          ...item,
          Listeners: formatPodcastRating(item.Listeners),
        };

        return newItem;
      });
      const episode = episodes.find(
        (item) => item['Episode Title'] === episodeName
      );

      if (episode) {
        setCurrentCampaign(episode);
        setCurrentShow(showName);
      }
    } catch (error) {
      setCurrentCampaign(null);
      setCurrentShow(null);
    } finally {
      setLoading(false);
    }
  };

  if (!organization) {
    return null;
  }

  return (
    <>
      {selectedCampaignId ? (
        <>
          {loading || !details ? (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              className={classes.container}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box className={classes.container}>
              <Box className={classes.header}>
                <Typography
                  className={textClasses.xxlBold}
                  style={{ color: '#344054' }}
                >
                  {details.name}
                </Typography>
                <Typography
                  className={textClasses.baseRegular}
                  style={{ color: '#98A2B3', marginTop: 8 }}
                >
                  {details.description}
                </Typography>
              </Box>
              <Box className={classes.statsContainer}>
                <Stat
                  title='Impressions Analyzed'
                  info='Impressions Analyzed'
                  value={details.totalImpressions.toString()}
                  description={`${details.analyzedImpressions} of ${details.totalImpressions}`}
                />
                <Stat
                  title='Show Impression Alignment'
                  value={
                    (
                      (details.showAlignedImpressions /
                        details.totalImpressions) *
                      100
                    ).toFixed(0) + '%'
                  }
                  description={`${details.showAlignedImpressions} of ${details.totalImpressions}`}
                />
                <Stat
                  title='Episode Impression Alignment'
                  value={
                    (
                      (details.episodeAlignedImpressions /
                        details.totalImpressions) *
                      100
                    ).toFixed(0) + '%'
                  }
                  description={`${details.episodeAlignedImpressions} of ${details.totalImpressions}`}
                />
                <Stat
                  title='Impressions Aligned to Brand Standard'
                  value={
                    (
                      (details.alignedImpressions / details.totalImpressions) *
                      100
                    ).toFixed(0) + '%'
                  }
                  description={`${details.alignedImpressions} of ${details.totalImpressions}`}
                />
                <Stat
                  title='Shows Aligned to Brand Standards'
                  value={
                    (
                      (details.showAlignedCount / details.totalImpressions) *
                      100
                    ).toFixed(0) + '%'
                  }
                  description={`${details.showAlignedCount} of ${details.totalImpressions}`}
                />
                <Stat
                  title='Episodes Aligned to Brand Standards'
                  value={
                    (
                      (details.episodeAlignedCount / details.totalImpressions) *
                      100
                    ).toFixed(0) + '%'
                  }
                  description={`${details.episodeAlignedCount} of ${details.totalImpressions}`}
                />
              </Box>
              <Box className={classes.options}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'normal' },
                  }}
                  InputProps={{
                    placeholder: 'Search campaigns...',
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon
                          htmlColor='#858591'
                          style={{ fontSize: 20 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  className={classes.searchBar}
                />
                <ButtonDropdown
                  id='search-group'
                  selectedOption={group}
                  options={SEARCH_GROUPS}
                  handleSelect={setGroup}
                />
                <ButtonDropdown
                  id='campaign-brand-filter'
                  defaultLabel='Select brand standard filter'
                  selectedOption={brandStandardFilterOption}
                  options={[
                    { label: 'None', value: 'No brand standard filter' },
                    ...customFilters.map((filter) => ({
                      label: filter.name,
                      value: filter.id,
                    })),
                  ]}
                  handleSelect={(option) => {
                    if (option.value === 'No brand standard filter') {
                      setBrandStandardFilterOption(null);
                    } else {
                      setBrandStandardFilterOption(option);
                    }
                  }}
                />
                <Button
                  className={classes.addBtn}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  + Add To
                </Button>
                <Button
                  className={classes.addBtn}
                  onClick={() => {
                    setCreateNewModal(true);
                  }}
                >
                  + Create New
                </Button>
                <Button
                  disabed={showsDownloading}
                  className={classes.downloadBtn}
                  onClick={handleDownload}
                >
                  {showsDownloading ? <Spinner size={12} /> : 'Download'}
                </Button>
              </Box>
              <Box className={classes.items}>
                <TableContainer
                  component={Paper}
                  style={{
                    overflowX: 'scroll',
                    boxShadow: '0px 0px 0px 1px #E0E0E0',
                    borderRadius: 4,
                    flex: 1,
                    flexGrow: 1,
                  }}
                >
                  <TableWrapper
                    stickyHeader
                    style={{
                      borderRadius: 4,
                      height: '100%',
                      borderCollapse: 'collapse',
                    }}
                  >
                    <TableHead
                      style={{
                        position: 'sticky',
                        top: -1,
                        zIndex: 2,
                      }}
                    >
                      <TableRow>
                        {columns.map((column: string, index: number) => (
                          <HeaderCell
                            key={column}
                            sortDirection={false}
                            style={
                              index === 0
                                ? {
                                    minWidth: 300,
                                    padding: '4px 10px',
                                    borderRadius: 0,
                                    border: '1px solid #F0F0F1',
                                    borderBottom: 'none',
                                    background: '#F8F8F8',
                                  }
                                : {
                                    minWidth: [
                                      'Aligned',
                                      'Impressions',
                                    ].includes(column)
                                      ? 120
                                      : 220,
                                    padding: '4px 10px',
                                    borderRadius: 0,
                                    border: '1px solid #F0F0F1',
                                    borderBottom: 'none',
                                    background: '#F8F8F8',
                                  }
                            }
                          >
                            <span>{column}</span>
                          </HeaderCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {itemsLoading ? (
                      <TableRow style={{ height: '100%', border: 'none' }}>
                        <TableCell colSpan={columns.length}>
                          <Spinner />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableBody>
                        {group.value === 'episode'
                          ? items.map((item, index) => (
                              <CampaignItemEpisodeRow
                                key={`${item.id}-${index}`}
                                columns={columns}
                                garmScoreColumns={garmScoreColumns}
                                data={item}
                                onClick={(showName, episodeName) => {
                                  fetchGarmScoresPerShow(showName, episodeName);
                                }}
                              />
                            ))
                          : showItems.map((data, index) => {
                              const item = data.show;
                              const newItem = {
                                ...item,
                                iabCategories:
                                  item.iabCategories &&
                                  Object.keys(item.iabCategories)
                                    .map((key) => ({
                                      count: item.iabCategories[key],
                                      name: key,
                                    }))
                                    .sort((a: any, b: any) => b.count - a.count)
                                    .slice(0, 3),
                                show: decode(item.show),
                                showOnDB: item.show,
                                listenerCount: formatPodcastRatingNumber(
                                  item.rating
                                ),
                              };
                              if (
                                item.riskHistories &&
                                item.riskHistories.current
                              ) {
                                Object.keys(item.riskHistories.current).forEach(
                                  (key: string) => {
                                    const historyValues =
                                      item.riskHistories.current[key];
                                    if (historyValues) {
                                      if (key === 'electionsPercentage') {
                                        newItem[key] = {
                                          value: historyValues.medianRiskValue,
                                          up: historyValues.episodeCountHigherThanAverage,
                                          down: historyValues.episodeCountLowerThanAverage,
                                        };
                                      } else {
                                        const score =
                                          historyValues.medianRiskValue;
                                        const oldScore =
                                          item.riskHistories.prev &&
                                          item.riskHistories.prev[key]
                                            ? item.riskHistories.prev[key]
                                                .medianRiskValue
                                            : undefined;
                                        const updatedShow =
                                          score &&
                                          oldScore &&
                                          score !== oldScore;

                                        newItem[key] = {
                                          ...historyValues,
                                          score,
                                          oldScore,
                                          updatedShow,
                                        };
                                      }
                                    }
                                  }
                                );
                              }
                              return (
                                <Row
                                  key={`${item.showId}-${index}`}
                                  name={item.show}
                                  columns={columns}
                                  garmScoreColumns={garmScoreColumns}
                                  data={{
                                    ...newItem,
                                    impressionCount: data.impressionCount,
                                    isAligned: data.isAligned,
                                  }}
                                  isHost={false}
                                  hostData={{}}
                                  onClick={() => {}}
                                />
                              );
                            })}
                        {/* This final row exists so that the other table rows don't expand in height (and look distorted) if there aren't enough rows to fill the full height of the table */}
                        <TableRow style={{ height: '100%', border: 'none' }}>
                          <TableCell />
                        </TableRow>
                      </TableBody>
                    )}
                    <Footer
                      style={{
                        position: 'sticky',
                        bottom: -1,
                        zIndex: 1,
                      }}
                    >
                      <TableRow>
                        <TableCell
                          colSpan={1000}
                          style={{
                            padding: '6px 12px',
                            boxShadow: 'inset 0 7px 2px -7px rgba(0,0,0,0.4)',
                          }}
                        >
                          <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            style={{
                              position: 'sticky',
                              top: '50%',
                              left: 20,
                              maxWidth: 500,
                            }}
                          >
                            {/* @ts-ignore */}
                            <TablePagination
                              rowsPerPageOptions={[10, 20, 50, 100]}
                              SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              count={totalItemsCount}
                              page={page}
                              rowsPerPage={pageSize}
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                fontSize: 12,
                              }}
                              nextIconButtonProps={{
                                size: 'small',
                              }}
                              backIconButtonProps={{
                                size: 'small',
                              }}
                            />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </Footer>
                  </TableWrapper>
                </TableContainer>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <NoCampaign onNewCampaign={() => setOpenCreateModal(true)} />
      )}

      <CampaignModal
        open={openCreateModal}
        closeModal={() => setOpenCreateModal(false)}
      />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby='report-dashboard-modal-title'
        aria-describedby='report-dashboard-modal-description'
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 800,
        }}
      >
        <Box sx={{ ...modalStyle }}>
          <CancelIcon
            className={classes.closeIcon}
            onClick={() => setOpenModal(false)}
          />
          <DashboardList
            title='Select Dashboard'
            dashboards={garmDashboards}
            onSelectDashboard={handleSelectDashboard}
            onChangeFavorite={() => {}}
            selectedDashboardId={null}
            isDashboardSelection
          />
        </Box>
      </Modal>
      <Modal
        open={createNewModal}
        onClose={() => setCreateNewModal(false)}
        aria-labelledby='report-graph-modal-title'
        aria-describedby='report-graph-modal-description'
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 800,
        }}
      >
        <Box sx={{ ...createNewModalStyle }}>
          <CancelIcon
            className={classes.closeIcon}
            onClick={() => setCreateNewModal(false)}
          />
          <Typography variant='body1' style={{ fontSize: 14 }}>
            Dashboard Name
          </Typography>
          <TextInput
            default='Enter name'
            value={newDashboardName}
            onChange={(name) => setNewDashboardName(name)}
            className={classes.description}
          />
          <Box
            style={{
              display: 'flex',
              gap: 12,
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              title='Cancel'
              variant='outlined'
              height={36}
              onClick={() => setCreateNewModal(false)}
            />
            <Button
              disabled={!newDashboardName}
              title='Create'
              height={36}
              onClick={() => {
                handleNewDashboard(newDashboardName);
              }}
            />
          </Box>
        </Box>
      </Modal>
      <Dialog open={!!currentCampaign} fullScreen>
        <GarmArticleContent
          episode={currentCampaign}
          show={currentShow || ''}
          showGenres={[]}
          onClose={() => {
            setCurrentCampaign(null);
            setCurrentShow(null);
          }}
        />
      </Dialog>
    </>
  );
}
